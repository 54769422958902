import React from "react"

const FeaturesImage = props => (
  <svg x="0px" y="0px" viewBox="0 0 1127.68 827.11" {...props}>
    <style>
      {
        ".featuresImg116{fill:#e9d6b8}.featuresImg117{fill:#163f59}.featuresImg118{fill:#161711}.featuresImg119{fill:#e2c59c}.featuresImg120{fill:#b0bfde}.featuresImg121{fill:#ff5931}.featuresImg126{fill:#7dc2f5}.featuresImg133{fill:#ffab01}.featuresImg134{fill:#fec12f}.featuresImg142{fill:#194866}.featuresImg159{fill:#0253a2}.featuresImg165{fill:#282723}.featuresImg184{fill:#e7f5fe}"
      }
    </style>
    <g id="Layer_6">
      <path
        d="M878.85 638.21c-79.32-8.87-324.95-38.2-472.65-71.09-273.9-60.98-505.42-23.63-362.75 56.78 39.87 22.47-89.95 122.97 201.06 175.4 255.45 46.02 522.14 23 650.97 14.46 128.84-8.55 190.69-39.21 219.36-52.95 25.23-12.09 41.27-91.6-235.99-122.6z"
        fill="#e9f6ff"
      />
      <path
        className="featuresImg116"
        d="M831.84 732.09L830.19 757.3 814.09 758.25 805.89 756.86 823.3 751.99 823.35 730.25z"
      />
      <path
        className="featuresImg117"
        d="M863.62 613.3s-42.31 51.88-43.1 55.82c-.79 3.93 1.45 64.19 1.45 64.19l11.51 3.87s2.92-52.09 5.23-57c3.05-6.47 51.27-45.73 48.25-61.31-3.02-15.58-23.34-5.57-23.34-5.57z"
      />
      <path
        className="featuresImg118"
        d="M829.63 761.37l-3.25-.39-1.28-2.61-14.39 1.36-8.78-1.04s.93-1.26 1.55-1.58c1.83-1 8.57-2.14 8.57-2.14s-.52 1.99.41 2.44c.94.45 10.84-.3 13.24-.74 4.12-.74 5.25-1.78 5.44-1.52.5.68-1.51 6.22-1.51 6.22z"
      />
      <path
        className="featuresImg119"
        d="M771.53 635.69s-.57-.79-1.2-.37c-1.04.68-2.41 2.8-2.87 3.58-.51.86-2.24 5.33-2.24 5.33s1.55-.86 2.25-2.66c1.02-2.62 4.06-5.88 4.06-5.88z"
      />
      <path
        className="featuresImg119"
        d="M771.15 636.22c-1.45 1.05-2.07 3.06-2.22 5.6-.15 2.4-1.43 3.25-1.43 3.25s-.07-1.56.55-5.27c.17-1.04.42-2.17.95-3.06 1.58-2.71 5.89-3.23 2.15-.52z"
      />
      <path
        className="featuresImg116"
        d="M815.97 603.93c-4.79 8.84-32.85 23.7-32.85 23.7s-11.94 2.57 21.34-23.62c9.99-7.86 12.7-2.26 11.51-.08z"
      />
      <path
        className="featuresImg116"
        d="M770.24 637.98l-.43-.78 1-2.96s3.78-4.16 5.74-5.21c1.97-1.05 7.59-7.57 8.48-8.15.88-.59 5.16 1.81 5.16 1.81s-7.22 5.17-9.91 7.46c-2.7 2.29-5.43 6.9-6.46 7.84-1.74 1.6-2.54 2.51-2.54 2.51s-.71-1.06 1.89-3.73c.69-.7-.46-1.1-.46-1.1l-2.47 2.31z"
      />
      <path
        className="featuresImg116"
        d="M771.89 634.43s-2.05 2.01-2.32 3.44c-.18.99-.31 5.79-.31 5.79s1.16-1.35 1.21-3.28c.09-2.82 1.42-5.95 1.42-5.95z"
      />
      <path
        className="featuresImg120"
        d="M792.56 610.87l12.4-11.79s18.36-32.94 22.91-33.92c5.41-1.15 13.95.15 13.95.15l-22.9 39.55-12.08 15.33-14.28-9.32z"
      />
      <path
        className="featuresImg121"
        d="M865.86 635.01s-13.94.06-16.5-3.31c-3.36-4.42.74-12.53-4.11-22-4.85-9.46-7.03-12.11-9.4-14.37-2.38-2.25-5.85-4.39-6.32-8.58-.48-4.18-1.44-10.99-.68-12.16 1.21-1.89 2.46-4.04 2.81-6.46.18-1.33 34.2 66.88 34.2 66.88z"
      />
      <path
        className="featuresImg134"
        d="M722 357.94c-6.02 1.13-10.78 6.06-11.34 12.47-.41 4.42 1.38 8.49 4.36 11.35 10.37 9.59 16.36 23.69 15.02 38.85-2.3 26.01-25.24 45.23-51.26 42.91-26-2.31-45.21-25.24-42.89-51.25 1.32-15.17 9.72-28 21.64-35.61 3.44-2.28 5.88-5.96 6.25-10.4.57-6.38-3.25-12.09-8.95-14.26l-109.95-9.77-9.53 107.62c.11 7.08 5.47 13.12 12.7 13.77 4.43.37 8.5-1.37 11.36-4.37 9.58-10.37 23.68-16.4 38.85-15.03 26 2.31 45.19 25.27 42.93 51.26-2.32 26.01-25.27 45.22-51.26 42.91-15.19-1.33-28-9.75-35.64-21.65-2.28-3.44-5.97-5.88-10.4-6.28-7.23-.63-13.56 4.35-14.92 11.32l-9.55 107.6 287.18 25.45 25.46-287.15L722 357.94z"
      />
      <path
        d="M512.13 629.58V521.96h.01c.52-7.26 6.53-13.01 13.95-13.01 4.41 0 8.34 2.11 10.9 5.32 8.64 11.21 22.16 18.41 37.41 18.41 26.09 0 47.26-21.16 47.26-47.26 0-26.12-21.17-47.26-47.26-47.26-15.23 0-28.75 7.22-37.41 18.4-2.55 3.22-6.47 5.34-10.9 5.34-7.42 0-13.43-5.73-13.95-13.02h-.01v-107.6h-108.1c-7.04-.74-12.55-6.62-12.55-13.87 0-4.42 2.1-8.33 5.33-10.9 11.17-8.65 18.44-22.16 18.44-37.39 0-26.12-21.18-47.27-47.29-47.27-26.09 0-47.28 21.15-47.28 47.27 0 15.23 7.23 28.71 18.4 37.39 3.22 2.55 5.34 6.46 5.34 10.9 0 7.25-5.52 13.13-12.56 13.87H223.83v288.29h288.3z"
        fill="#387bff"
      />
      <g>
        <path
          className="featuresImg126"
          d="M574.29 149.05c-15.23 0-28.74 7.23-37.38 18.4-2.59 3.22-6.47 5.33-10.92 5.33-7.39 0-13.4-5.75-13.94-13.01h-.1v73.08h.1c.54-7.29 6.55-13.02 13.94-13.02 4.43 0 8.33 2.11 10.91 5.33 8.65 11.18 22.14 18.42 37.4 18.42 26.09 0 47.28-21.17 47.28-47.29-.01-26.11-21.21-47.24-47.29-47.24z"
        />
        <path
          className="featuresImg126"
          d="M223.83 52.16v288.29h111.94c.62-.24 1.18-.54 1.73-.86.07-.04.15-.09.22-.11.54-.36 1.06-.71 1.55-1.1.03-.01.06-.06.11-.09.88-.73 1.63-1.55 2.32-2.46.11-.17.24-.3.36-.46.21-.32.39-.67.59-1.01.2-.33.4-.73.56-1.11.15-.3.26-.6.39-.92.17-.5.31-.96.44-1.48.05-.28.13-.54.18-.81.11-.81.23-1.62.23-2.45 0-4.45-2.13-8.36-5.34-10.93-11.17-8.65-18.4-22.17-18.4-37.38 0-26.1 21.18-47.29 47.28-47.29s47.29 21.18 47.29 47.29c0 15.24-7.25 28.74-18.44 37.4-3.23 2.57-5.33 6.47-5.33 10.9 0 .83.11 1.63.25 2.45.03.26.13.54.19.81.11.52.26.98.41 1.48.13.32.26.62.41.92.16.38.35.78.58 1.11.17.34.35.69.57 1.01.11.16.23.29.36.46.66.91 1.44 1.74 2.3 2.46.06.03.1.08.12.09.49.39 1 .74 1.53 1.07.06.04.14.1.21.14.57.32 1.14.63 1.75.86h111.96V52.16H223.83z"
        />
      </g>
      <path
        d="M942.25 301.29l106.82-16.28L1005.63 0 720.64 43.46l16.86 110.66c.33.57.71 1.08 1.11 1.6.05.05.11.12.17.2.39.47.83.94 1.27 1.34.03.03.06.07.1.1.87.77 1.81 1.41 2.81 1.92.19.09.33.21.5.29.34.16.73.29 1.07.42.39.16.79.3 1.19.42.32.09.65.17.98.22.5.1 1.01.18 1.52.23.3.02.55.05.83.05.81.01 1.62 0 2.46-.13 4.38-.68 7.93-3.35 9.98-6.92 6.86-12.37 19.14-21.52 34.18-23.83 25.79-3.94 49.92 13.79 53.85 39.6 3.93 25.79-13.81 49.92-39.61 53.84-15.04 2.3-29.52-2.8-39.72-12.56-3.05-2.78-7.21-4.3-11.6-3.64-.83.12-1.62.35-2.37.61-.27.09-.51.19-.78.31-.49.2-.95.43-1.4.66-.3.17-.57.33-.84.51-.37.23-.71.48-1.04.73-.28.25-.62.48-.89.73-.15.12-.26.29-.4.43-.81.8-1.51 1.68-2.09 2.68-.03.05-.06.07-.08.12-.31.53-.58 1.08-.84 1.67-.02.06-.06.16-.09.24-.23.59-.43 1.21-.58 1.81l16.87 110.69 106.86-16.27c7.05-.32 13.4 4.67 14.48 11.8.67 4.41-.82 8.56-3.6 11.59-9.77 10.22-14.89 24.69-12.6 39.74 3.92 25.81 28.05 43.56 53.86 39.6 25.82-3.93 43.56-28.03 39.61-53.83-2.3-15.08-11.49-27.33-23.85-34.19-3.54-2.05-6.23-5.61-6.91-10-1.09-7.17 3.49-13.78 10.34-15.61z"
        fill="#9fc400"
      />
      <g>
        <path
          className="featuresImg117"
          d="M1068.94 309.37s-8.01-3.89-8.66-8.2c-.66-4.32 5.77.79 1.37 1-4.4.22-9.21-7.73-5.23-10.14 3.98-2.4 7.84-2.15 9.47 1.61 1.64 3.77-1.4 3.54-1.28.88.12-2.65 7.38-4.97 6.89 4.19-.49 9.16-2.79 8.52-1.29 3.16 1.5-5.37 6.17-6.55 5.11.19-1.05 6.73-6 7.42-5.39 4.49.61-2.93 10.78-3.81 8.57 1.15-2.21 4.96-5.07 10.78-8.64 8.74-3.57-2.03 11.89-9.28 9.2 2.57-2.69 11.86-7.38 4.97-6.3 2.58 1.08-2.38 10.72 8.98-.62 6.65-11.33-2.34-5.44-4.2-4.88-6.94.56-2.74 5.71 8.41-1.88 10.43-7.59 2.02-11.55-1.57-7.61-6.05 3.94-4.48 7.69 1.32 3.5 4.49s-3.05 7.21-2.3 8.25c.74 1.03-8.59-.33-7.63-5.23.96-4.91 10.75-25.94 17.6-23.82z"
        />
        <path
          className="featuresImg116"
          d="M1059.66 298.93l-7.67 3.03-1.22 2.14-6.79 2.86-.06 3.91-6.07 8.43s-3.5 4.4 2.03 5.32l5.53.93s-2.91 5.84-4.04 9.59c-3.32 10.94 2.11 13.53 2.11 13.53s7.08 3.25 9.45 4.1c1.6.57.66-8.18 1.4-11.62 1.44-6.62 3.35-13.62 3.35-13.62s.61.79 2 .98c1.39.18 8.66-.68 8.32-3.88-.35-3.2-4.57-4.38-7.02-2.99-2.45 1.38-3.32-2.17-.07-6.43s13.98-1.5 4.4-9.1c-6.43-5.13-5.65-7.18-5.65-7.18z"
        />
        <path
          className="featuresImg119"
          d="M1000.17 300.29l7.94 19.87s3.36 5.65 4.41 7.24c1.06 1.59-2.12 7.06-5.65 3.36-3.53-3.71-12.89-26.84-11.13-29.31 1.77-2.48-2.92-7.82 4.43-1.16z"
        />
        <path
          className="featuresImg116"
          d="M1007.93 328.46s-6.74-15.23-8.3-20.3c-.71-2.3-2.12-6.18-1.41-7.59.71-1.41 1.24-4.59.88-5.3-1.19-2.37-3.36-6.71-3.53-8.12-.18-1.41-1.41-1.06-1.59.35-.18 1.41-.8 5.98-2.04 6.34-1.24.35-2.1 1.24-7.93.88-5.83-.35-6.26 1.06-4.85 1.76 1.41.71 5.08.82 6.31 2.06 1.24 1.24 4.97 4.85 6.03 7.15 1.06 2.3 2.65 7.24 3.71 11.12 1.06 3.89 3.18 24.72 7.77 24.02 4.6-.72 4.95-8.84 4.95-12.37z"
        />
        <path
          className="featuresImg134"
          d="M1030.83 609.53l-.47 2.35s0 2.3-.47 2.35c-25.9 3.06-41.2 3.3-41.2 3.3s-.92-2.11-.47-2.59c2.83-3.06 10.83-6.12 22.84-11.54 12-5.41 19.06 4.48 19.77 6.13zM1039.54 570.44s8.24 7.53 8.95 11.07c.71 3.53 4.95 12.71 4.95 14.6 0 1.88 3.06 1.88 3.06-.47 0-2.35-2.59-35.08-2.59-35.08l-2.35-.24-4.71-1.88-7.31 12z"
        />
        <path
          className="featuresImg117"
          d="M1003.76 542.89l29.9 23.78s8.24 7.53 7.53 7.53 3.3-9.54 4.95-11.77c1.21-1.64 4.24-3.53 4.24-3.53s-.29-1.1-3.06-1.65c-1.18-.24-3.53-1.18-2.12-1.65 1.41-.47-2.24-2.8-4.94-3.77-3.3-1.18-33.67-31.79-40.26-36.73-6.61-4.93 3.76 27.79 3.76 27.79z"
        />
        <path
          className="featuresImg142"
          d="M1004.93 428.46s-28.26 68.75-29.2 75.58c-.94 6.83 31.31 89 31.31 89s7.77 7.3 4.71 5.42c-3.06-1.88-3.06.94-3.06.94l.71 4.47 22.84 6.59-1.18-3.06-.24-1.65-5.65-5.65 2.12-2.82-27.55-89.95 14.36-32.26s6.83-8.48 11.77-17.66c4.94-9.18 14.36-13.42-.71-23.55-15.05-10.1-18.11-12.22-20.23-5.4z"
        />
        <path
          className="featuresImg133"
          d="M1037.66 337.11s-7.53-6.36-10.36-6.59c-2.83-.23-10.36-3.29-10.36-3.29s0-1.65-.71-1.42c-.71.24-5.89-3.29-5.89-3.29l-1.65 7.53s4.24 12.24 10.83 13.19c6.6.93 16.25-5.9 18.14-6.13z"
        />
        <path
          d="M1020.71 384.91s-13.42 29.9-15.77 36.5c-2.36 6.59-3.06 9.18-3.06 9.18l4 2.82s3.76-4.71 4.24-7.06c.46-2.36 10.59-41.44 10.59-41.44z"
          fill="#ecb6a0"
        />
        <path
          className="featuresImg134"
          d="M1034.13 339.46s1.65-3.53 4-3.3c2.35.24.94-.94 3.29-.71 2.36.23 11.54 3.53 12.95 4.24 1.41.71 2.36 1.41 2.36 4s3.3 8.95 3.06 13.66c-.24 4.71-14.83 65.46-16.72 74.17-1.89 8.71.94 16.48-5.89 14.84-6.83-1.65-23.78-6.59-28.49-10.6-4.71-4 .94-28.96 4-39.32s5.89-44.26 5.65-44.97c-.22-.71 15.79-12.01 15.79-12.01z"
        />
        <path
          className="featuresImg133"
          d="M1044.8 339.64s-5.26-.89-11.61-1.83c-6.36-.94-2.12-.24-10.36-.94-8.24-.71-8.95-1.65-9.89-4-.94-2.35-2.12-4.47-2.12-4.47s-4.94-7.77-5.65-8.01c-.7-.23.24.94-2.59 10.12-2.82 9.18-3.29 9.18-2.82 11.3.47 2.12 6.83 8.24 6.83 8.24l.7-1.41s4.53 7.21 9 7.21 18.83 6.54 18.83 6.54 13.21-12.39 9.68-22.75z"
        />
        <path
          className="featuresImg134"
          d="M1044.8 339.64s-5.26-.89-11.61-1.83c-6.36-.94-2.12-.24-10.36-.94-8.24-.71-8.95-1.65-9.89-4-.94-2.35-2.12-4.47-2.12-4.47s-2.77-4.36-4.46-6.64c.19 2.38.24 5.1-.28 6.64-1.05 3.14-.52 8.37.79 8.63 1.31.26 3.4-1.57 3.66-3.14.26-1.57 2.09-.26 2.09 1.05s-.52 6.28 2.62 8.11c3.14 1.83 7.59 4.18 13.86 4.71 6.28.52 10.72 2.62 7.58 3.92-3.14 1.31-7.06 3.4-3.92 3.14 3.14-.26 3.92-.26 2.09 1.31-1.28 1.1 1.14 2.57 4.23 1.85 3.57-4.55 7.93-11.84 5.72-18.34z"
        />
      </g>
      <g>
        <path
          className="featuresImg116"
          d="M884.46 752.06L885.7 779.48 869.59 780.43 861.4 779.04 878.81 774.18 875.38 748.66z"
        />
        <path
          className="featuresImg118"
          d="M885.14 783.56l-3.25-.4-1.28-2.61-12.98 1.06-10.53-1.59s.19-.82.67-1.11c1.82-1.07 9.94-1.85 9.94-1.85s-.79 2.32.15 2.77c.94.45 10.95-.55 13.35-.97 4.13-.76 5.25-1.79 5.44-1.53.5.69-1.51 6.23-1.51 6.23z"
        />
        <path
          className="featuresImg142"
          d="M894.48 620.67c2.88 18.76-19.01 73.1-19.01 73.1s5.38 17.65 6.07 26.25c1.19 14.89 5.12 34.55 5.12 34.55l-12.88-2.03s-18.13-56.82-17.92-63.93c.22-7.12 6.39-65.51 16.71-77.76 10.31-12.27 20.76 2.34 21.91 9.82z"
        />
        <path
          className="featuresImg165"
          d="M824.53 557.77c5.61 7.61 16.4 9.02 24.08 3.13 7.68-5.88 9.37-16.82 3.76-24.42-5.62-7.62-16.4-9.02-24.09-3.14-7.68 5.88-9.37 16.82-3.75 24.43z"
        />
        <path
          className="featuresImg165"
          d="M838.46 577.05c5.86 7.95 17.12 9.41 25.15 3.28 8.03-6.15 9.79-17.57 3.92-25.52-5.86-7.94-17.12-9.42-25.15-3.27-8.02 6.14-9.78 17.56-3.92 25.51zM806.1 555.8c4.3 5.85 12.49 7 18.27 2.58l-1.09-7.03c5.78-4.42 6.98-12.75 2.67-18.59l-2.92-3.97c-4.31-5.84-12.49-7-18.27-2.58l-2.62 2c-5.78 4.43-6.97 12.75-2.67 18.59l6.63 9z"
        />
        <path
          className="featuresImg116"
          d="M798.8 537.03c.73.96 1.55 1.89 2.45 2.79 6.07 5.98 13.96 8.03 18.89 5.25 2.4 2.97 5.06 5.27 7.53 7.06 2.81 2.04 8 2.5 8 2.5 4.25 4.17-6.58 23.36-6.77 22.3-.18-1.06-.37-9.65-2.87-15.77-1.73-4.26-5.06-6.32-7.91-7.33-4.69-1.06-8.28 1.75-8.28 1.75-.35.27-.77.36-1.17.29a9.106 9.106 0 01-1.96-1.96l-6.44-8.73c-1.77-2.42-2.22-5.44-1.47-8.15z"
        />
        <path
          d="M865.82 640.51s-11.26-23-17.18-34.44c-5.92-11.45-15.78-34.24-17.18-37-1.4-2.76-2.4-7.11-2.4-7.11s0-6.11 3.89-8.13c3.89-2.01 5.8-1.73 18.8 4.8 23.73 11.92 47.89 58.37 44.96 64.6-.15.3-7.42.21-7.42.21s-18.95 4.35-23.47 17.07z"
          fill="#d1dbea"
        />
        <path
          className="featuresImg120"
          d="M832 551.57L830.43 555.66 828.38 561.77 832.49 565.66 835.09 552.56z"
        />
        <path
          className="featuresImg116"
          d="M812.64 638.79c-4.6 6.73-9.56 7.5-12.31 9.51-2.75 2-6 3.69-5.96 3.69 3.58.31 5.14-1.81 6.71-2.32 1.57-.5.96-.51-.52 2-.84 1.44-4.61 2.31-6.96 3.2-2.04.77-2.76 1.64-2.76 1.64s3.34-.21 6.59-1.2c3.92-1.2 7.94-3.27 8.75-3.86 1.49-1.08 4.69-5.48 6.51-8.96 2.02-3.92 3.25-8.56-.05-3.7z"
        />
        <path
          className="featuresImg116"
          d="M840.22 598s-2.7-1.66-11.98 12.77c-9.27 14.42-19.72 37.71-19.72 37.71l24.17-31.46c.01 0 8.73-12.43 7.53-19.02z"
        />
        <path
          className="featuresImg120"
          d="M827.87 604.8s8.71-13.4 15.52-8.2c3.86 2.95 3.5 6.94.8 10.95-5.02 7.45-11.09 15.67-11.09 15.67l-13.67-2.66 8.44-15.76z"
        />
        <path
          className="featuresImg120"
          d="M831.57 596.79l3.41-34.42s1.86-4.08 5.15-4.12c4.75-.05 6.91 1.53 7.61 4.28 1.38 5.44 1.56 2.63-.02 22.5-1.44 18.16-1.82 25.67-10.28 25.44-7.9-.2-5.87-13.68-5.87-13.68z"
        />
        <path
          className="featuresImg119"
          d="M803.63 652.68s-4.92 4.04-6.17 4.45c-.85.28-5.15.91-5.15.91s1.69-1.63 3.4-1.98c1.71-.36 7.92-3.38 7.92-3.38z"
        />
      </g>
      <g>
        <path
          className="featuresImg116"
          d="M472.35 441.11c-.36 4.49-2.74 8.38-5.23 8.57h-7.1c-2.49-.19-4.86-4.08-5.23-8.57-.58-7.41-1.16-14.81-1.75-22.21-.38-4.44 1.32-13.35 5.1-15.99 3.45-2.34 7.41-2.34 10.86 0 3.78 2.63 5.49 11.54 5.1 15.99-.58 7.4-1.17 14.8-1.75 22.21z"
        />
        <path
          className="featuresImg117"
          d="M464.62 397.68v-5.14h10.9c3.67 0 6.64 3.03 6.64 6.76v5.14h-10.9c-3.67-.01-6.64-3.03-6.64-6.76z"
        />
        <path
          className="featuresImg116"
          d="M454.17 428.86c0 3.32 2.65 6.01 5.91 6.01h13.39c3.26 0 5.9-2.69 5.9-6.01v-27.27c0-3.33-2.64-6.02-5.9-6.02h-13.39c-3.26 0-5.91 2.69-5.91 6.02v27.27z"
        />
        <path
          className="featuresImg117"
          d="M464.2 392.54c-2.32 0-4.37 1.22-5.55 3.06h-2.28c-2.84 0-5.14 2.35-5.14 5.23v12.94h3.61v-5.26c0-2.04 1.63-3.68 3.62-3.68h10.92c3.67 0 6.64-3.03 6.64-6.76v-5.53H464.2z"
        />
        <path
          className="featuresImg116"
          d="M455.98 417.12c0-1.77-1.41-3.2-3.15-3.2h-.26c-1.74 0-3.15 1.43-3.15 3.2v3.89c0 1.77 1.41 3.21 3.15 3.21h.26c1.29 0 2.39-.79 2.88-1.91l.27-5.19z"
        />
        <path
          className="featuresImg159"
          d="M419.73 705.98l.32 9.14-7.28.59s-.49-1.1-1.11-1.05c-.52.04-1.1 1.23-1.8 1.29-1.53.13-3.23.26-3.91.32l-14.6 1.19s-.46-1.66-.11-2.28c.18-.31 1.36-.93 2.15-1.25 3.66-1.5 14.13-6.08 15.68-10.26 1.9-5.11 10.66 2.31 10.66 2.31z"
        />
        <path
          className="featuresImg126"
          d="M391.14 715.54c-.11.74.21 1.9.21 1.9l14.6-1.19c.68-.06 2.38-.19 3.91-.32.71-.06 1.29-1.25 1.8-1.29.62-.05 1.11 1.05 1.11 1.05l7.28-.59.18-1.94-29.09 2.38zM404.34 710.88c-.14.25-.59.01-1.17-.41-1.42-1.03-1.28-.92-.96-1.13.23-.15.5-.3 1.32.32.58.43.95.97.81 1.22zM406.22 709.75c-.14.25-.58.02-1.17-.41-1.42-1.03-1.28-.92-.96-1.13.23-.15.5-.31 1.32.32.59.43.96.97.81 1.22zM407.95 708.5c-.14.25-.58.02-1.17-.41-1.42-1.03-1.29-.92-.96-1.13.23-.15.5-.3 1.32.32.58.43.95.97.81 1.22z"
        />
        <g>
          <path
            className="featuresImg159"
            d="M491.95 712.41c.67.97.45 3.39.45 3.39s-11.91 2.49-15.86 1.78c-1.03-.18-2.81-1.02-4.49-1.92-.28-.15-.84-1.15-1.11-1.3-.51-.28-.72.28-1.16.02-1.13-.67-1.93-1.21-1.95-1.28-1.54-6.11-.33-8.73-.33-8.73s7.4-4.45 9.53-1.94c5.01 5.9 12.99 7.16 14.92 9.98z"
          />
          <path
            className="featuresImg126"
            d="M492.4 713.7c-3.67.84-11.38 2.54-12.84 2.37-5.97-.72-9.75-3.91-12.3-5.85.12.86.3 1.8.57 2.86.02.07.82.61 1.95 1.28.44.26.65-.3 1.16-.02.27.15.83 1.15 1.11 1.3 1.68.9 3.46 1.74 4.49 1.92 3.95.71 15.86-1.78 15.86-1.78s.13-1.08 0-2.08zM485.4 708.35c.07.26-.42.45-1.09.47-1.37.03-2.75.22-4.12.57-.68.17-1.27.11-1.32-.17-.05-.29.46-.69 1.13-.85 1.35-.32 2.72-.5 4.07-.52.65-.01 1.25.24 1.33.5zM482.93 706.7c.06.27-.43.51-1.09.59-1.35.17-2.7.49-4.04.95-.66.23-1.24.22-1.28-.07-.04-.29.45-.76 1.11-.97 1.33-.45 2.66-.75 4-.9.65-.07 1.24.13 1.3.4zM480.55 705.19c.06.28-.43.58-1.09.73-1.32.29-2.64.73-3.95 1.32-.64.29-1.2.32-1.23.02-.04-.3.44-.82 1.08-1.1 1.29-.57 2.61-.99 3.91-1.26.66-.14 1.22.02 1.28.29z"
          />
        </g>
        <path
          className="featuresImg117"
          d="M434.11 527.84L422.05 607.98 405.8 706.37 421.41 711.21 439.48 618.57 459.83 527.08z"
        />
        <path
          className="featuresImg117"
          d="M452.53 528.79l25.16 3.29.98 95.98 1.06 75.47s-5.07 1.57-8.73 3.7c-1.22.7-4.46 2.88-4.46 2.88l-4.78-86-9.23-95.32z"
        />
        <path
          className="featuresImg119"
          d="M501.35 439.58s9.93 1.95 13.99 3.18c11.28 3.42 15.15 5.82 17.88 8.92 2.74 3.1-3.28 3.47-6.32 6.25-3.04 2.79-27.39-1.24-32.56-4.95-5.16-3.72 3.66-13.4 7.01-13.4z"
        />
        <path
          className="featuresImg116"
          d="M459.36 430.37s-2.74 10.22-9.43 12.08c-6.69 1.86 10.34 9.91 19.17 7.43 8.82-2.47 9.13-10.22 9.13-10.22s-6.69.62-6.09-4.03c.6-4.65-10.35-9.91-12.78-5.26z"
        />
        <path
          className="featuresImg121"
          d="M471.23 445.23c2.16-1.36 5.17-6.19 6.39-8.05 1.22-1.86 10.65-1.55 20.08.62 9.43 2.17 13.69 3.33 14.6 3.64.91.31-7.3 1.32-10.04 10.92-2.74 9.6-3.42 11.15-3.42 11.15l-6.31-.93s-10.59 89.86-12.11 97.3c-1.52 7.43-38.93 12.31-52.13 4.98 0 0 9.15-84.88 10.67-91.38l-.59-31.03s12.09-1.92 15.44-1.92c-.01 0 3.02 13.71 17.42 4.7z"
        />
        <path
          className="featuresImg119"
          d="M420.99 466.49s-13.58 31.61-14.78 40.78c-1.2 9.16 4.85 9.19 10.4 6.11 4.4-2.44 13.6-24.84 16-28.51 2.4-3.67-11.02-22.66-11.62-18.38z"
        />
        <path
          className="featuresImg116"
          d="M406.21 507.26c1.2-9.16 14.78-40.78 14.78-40.78.31-2.21 4.06 1.81 7.27 6.71-1.54-1.58-2.7-2.2-2.86-1.01 0 0-13.58 31.62-14.78 40.78-.22 1.68-.3 3.04-.26 4.16-3.58-1.25-5.09-2.69-4.15-9.86zM531.99 449.34c-3.79-2.64-6.59-2.44-18.29 1.83-15.53 5.67-20.69 7.94-23.09 9.16-2.4 1.22-2.1 5.19-3.3 9.77-1.2 4.58-.9 10.38-.9 11.3 0 .91 5.7 4.27 5.7 4.27l2.4-3.36.9-5.49s-2.99-.3-4.2 0c-1.2.3 3.26-2.51 2.99-7.32-.3-5.5 2.4-6.41 6.6-6.11 4.2.31 23.39 1.22 26.99.61 3.6-.61 8.7-3.05 7.49-7.63-1.19-4.59-1.71-5.93-3.29-7.03z"
        />
        <path
          className="featuresImg116"
          d="M419.41 509.71l19.94 23.4s-41.91-16.06-31.37-22.47c8.23-5.01 11.43-.93 11.43-.93z"
        />
        <path
          className="featuresImg121"
          d="M443.25 443.78s-9.15-5.99-16.35 7.43c-4.29 8.02-7.91 16.28-10.79 23.64 0 0 15.15 18.81 20.04 11.03 1.41-2.25 5.12-7.56 6.16-10.04 6.15-14.78.94-32.06.94-32.06z"
        />
        <g>
          <path
            className="featuresImg116"
            d="M432.29 522.33c1.69.63 1.6.52 1.6.52s-.23 2.34 1.03 4.14c.63.9 3.01 3.45 3.01 3.45l3.48 3.83 1.75 1.64s1.06.86 1.14 1.28c.25 1.23-.75.96-.75.96l-7.33-5.78 5.37 7.22s.54 1.04.14 1.51c-.37.43-.61.15-.61.15l-7.93-6.71 2.36 4.04s.5 1.03.14 1.51c-.4.52-.49.67-.49.67l-5.9-7.54c-.01-.02 1.3-11.52 2.99-10.89z"
          />
          <path
            className="featuresImg116"
            d="M435.31 533.79l3.49 5.3s.91 1.09 1.02 1.87c.13.89-.62 1.04-.62 1.04l-6.02-7.47 2.13-.74z"
          />
        </g>
      </g>
      <g>
        <path
          className="featuresImg120"
          d="M129.81 699.8L125.35 699.8 228.97 313.08 233.43 313.08z"
        />
        <path
          className="featuresImg120"
          d="M190.76 699.8L186.3 699.8 289.92 313.08 294.38 313.08z"
        />
        <path
          className="featuresImg120"
          d="M224.77 337.06L225.97 332.6 288.41 332.6 287.21 337.06z"
        />
        <path
          className="featuresImg120"
          d="M215.66 371.06L216.86 366.6 279.3 366.6 278.1 371.06z"
        />
        <path
          className="featuresImg120"
          d="M206.55 405.07L207.74 400.61 270.19 400.61 268.99 405.07z"
        />
        <path
          className="featuresImg120"
          d="M197.44 439.08L198.63 434.62 261.08 434.62 259.88 439.08z"
        />
        <path
          className="featuresImg120"
          d="M188.33 473.08L189.52 468.63 251.96 468.63 250.76 473.08z"
        />
        <path
          className="featuresImg120"
          d="M179.21 507.09L180.41 502.63 242.85 502.63 241.65 507.09z"
        />
        <path
          className="featuresImg120"
          d="M170.1 541.09L171.29 536.64 233.74 536.64 232.54 541.09z"
        />
        <path
          className="featuresImg120"
          d="M160.99 575.11L162.18 570.65 224.62 570.65 223.42 575.11z"
        />
        <path
          className="featuresImg120"
          d="M151.88 609.12L153.07 604.66 215.51 604.66 214.32 609.12z"
        />
        <path
          className="featuresImg120"
          d="M142.76 643.12L143.96 638.66 206.4 638.66 205.2 643.12z"
        />
        <path
          className="featuresImg120"
          d="M133.65 677.13L134.85 672.67 197.29 672.67 196.09 677.13z"
        />
      </g>
      <g>
        <path
          className="featuresImg116"
          d="M253.3 124.77s7.74.37 9.27-1.18c1.53-1.54 6.74-7.53 6.74-7.53l1.19-1.64s-1.16-2.16-4.62 1.9c-3.46 4.06-2.9 1.25-2.9 1.25l1.68-4.75 1.59-5.95 1.06-2.57s.71-1.42.58-1.88c-.38-1.34-1.35-.54-1.35-.54l-5.01 9.69 2.07-10.03s.04-1.32-.64-1.57c-.65-.23-.76.19-.76.19l-5.17 10.93.45-5.27s0-1.29-.65-1.56c-.72-.29-.9-.39-.9-.39l-4.71 20.17 2.08.73z"
        />
        <path
          className="featuresImg116"
          d="M259.8 110.65l1.02-7.12s.41-1.57.12-2.4c-.34-.95-1.23-.68-1.23-.68l-2.66 10.64 2.75-.44zM236.61 149.96s1.71-11.24 17.53-25.58c5.34-4.84-.56-2.34-7.46-1.29-6.9 1.05-17.48 14.06-20.77 28.33-3.29 14.28 10.7-1.46 10.7-1.46z"
        />
        <path
          transform="rotate(-38.794 245.98 127.045)"
          className="featuresImg184"
          d="M242.15 122.27H249.83V131.79999999999998H242.15z"
        />
        <path
          className="featuresImg134"
          d="M216.73 224.53s-13.09-56.48-11.36-60.48c7.02-16.25 32.87-43.92 32.87-43.92l12.53 11.92s-20.22 25.5-22.96 28.41c-1.94 2.06 3.51 24.01 12.65 38.6 4.39 7-22.05 24.14-23.73 25.47z"
        />
        <path
          className="featuresImg116"
          d="M316.79 163.57l1.52-38.42s-13.74 25.42-11.68 37.52c2.05 12.12 10.16.9 10.16.9z"
        />
        <path
          className="featuresImg116"
          d="M316.13 133.17s-4.87-4.36-4.76-6.22c.11-1.87.96-8.65.96-8.65l.39-1.72s2.12-.65 1.5 3.91c-.62 4.56.9 2.49.9 2.49l2.14-3.93 3.01-4.62 1.07-2.22s.51-1.3.9-1.5c1.12-.6 1.16.46 1.16.46l-3.43 8.96 5.41-7.43s.85-.84 1.42-.58c.54.23.33.56.33.56l-4.15 9.81 3.22-3.52s.85-.8 1.42-.58c.62.23.8.28.8.28l-10.55 15.27-1.74-.77z"
        />
        <path
          className="featuresImg116"
          d="M321.61 120.6l4.11-5.01s.8-1.23 1.52-1.56c.83-.39 1.19.31 1.19.31l-5.46 8.14-1.36-1.88z"
        />
        <path
          transform="rotate(-85.434 312.521 147.668)"
          className="featuresImg184"
          d="M307.74 143.34H317.27V151.99H307.74z"
        />
        <path
          className="featuresImg134"
          d="M264.77 187.09l37.13-16.01 5.8-25.2s11.12-1.04 11.92 1.65c5.55 18.62-5.36 45.88-5.36 45.88l-51.13 20.92s-13.02 1.91-25.47-9c-9.02-7.92 24.12-16.8 27.11-18.24z"
        />
        <g>
          <path
            className="featuresImg118"
            d="M208.52 428.99c.57-.68.16-2.47.16-2.47s-9.84-1.1-13-.35c-.82.19-2.21.9-3.52 1.65-.22.12-.61.88-.83 1.01-.39.24-.6-.16-.94.05-.88.55-1.49.99-1.5 1.04-.86 4.51.29 6.33.29 6.33s8.1 2.52 9.95.84c4.67-4.24 8.15-6.61 9.39-8.1zM239.64 432.71l1.4 7.33h7.48s.41-1.04 1.04-1.04c.54 0 1.23 1.04 1.95 1.04H270.52s.34-1.54-.08-2.07c-.2-.27-1.46-.75-2.29-.98-3.86-1.08-14.91-4.47-16.83-8.16-2.34-4.49-11.68 3.88-11.68 3.88z"
          />
          <path
            className="featuresImg116"
            d="M245.58 185.64l1.79-8.88s6.31.49 7.53-2.91c2.48-6.81.41-17.7-1.09-20.75-4.26-8.68-6.06-23.9-25.95-11.8-7.57 4.6 4.12 26.91 4.99 31.74.86 4.83-1.72 10.39-1.72 10.39l4.65 3.49 9.8-1.28z"
          />
          <path
            className="featuresImg117"
            d="M222.06 327.42s2.91 1.27-5.67 41.99c-6.18 29.32-11.47 50.77-13.94 60.48-.96 3.77-2.03 3.09-2.03 3.09l-8.18.91-3.89-3.38 11.68-66.44 22.03-36.65z"
          />
          <path
            className="featuresImg117"
            d="M241.19 281.82s-18.72 77.53-33.42 112.28c-8.61 20.35-8.69-18.72-8.69-18.72l13.38-112.58 39.07-1.89c.01 0-4.42 1.37-10.34 20.91z"
          />
          <path
            className="featuresImg117"
            d="M227.56 261.13s14.15 100.37 15.63 104.9c5.03 15.32 12.79-4.77 14.56-9.87 1.78-5.12 4.74-42.92 4.15-70.51l-.59-27.59-33.75 3.07z"
          />
          <path
            className="featuresImg117"
            d="M241.77 340.92s-2.14 11.97-3.92 27.26c-1.31 11.23-1.34 43.31.73 65.59.12 1.36 14.29-2.79 14.29-2.79s5.17-62.36 6.36-76.32c1.19-13.96-8.58-23.71-17.46-13.74z"
          />
          <path
            className="featuresImg134"
            d="M209.61 194.37l16.71-7.58 4.61-4.36 17.76-.43 3.11 5.12 13.77 5.97s3.4 5.34.44 25.16c-3.27 21.97-3.96 55.17-3.96 55.17l-52.06 2.51c-.01.01 4.5-70.9-.38-81.56z"
          />
          <path
            className="featuresImg142"
            d="M248.21 155.65s-2.25-1.38-2.59.34c-.35 1.73.86 5.53 1.9 5.88 1.04.35 1.38 5.01 1.38 5.01l-2.77 5.19h-14s-4.33-1.9-4.68-5.01c-.35-3.12-3.8-25.08-3.8-25.08l9.86-5.19s5.19-2.6 8.82-3.63c5.57-1.59 6.74 1.38 6.57 2.94-.17 1.55 1.56 1.73 3.8 1.73 2.25 0 5.02 1.73 3.81 2.77-.5.43 1.73 1.79.85 3.45-1.27 2.36-5.52 3.13-5.87 6.93-.15 1.73-.87 7.43-3.28 4.67z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default FeaturesImage
