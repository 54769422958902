import React from "react";

const MirageLogo = props => (
  <svg fill="none" viewBox="0 0 138 48" {...props}>
    <path
      fill="#9FA6B2"
      fillRule="evenodd"
      d="M30.316 5c3.984 0 7.213 3.256 7.213 7.273a7.272 7.272 0 01-4.771 6.845l5.814 10.462h3.98c.613 0 1.11.5 1.11 1.118 0 .618-.497 1.118-1.11 1.118H37.94a.97.97 0 01-.034 0H1.11c-.612 0-1.109-.5-1.109-1.118 0-.618.497-1.118 1.11-1.118h3.98l10.353-18.562a1.107 1.107 0 011.896-.063l5.948 9.189 1.85-2.809a7.28 7.28 0 01-2.035-5.062c0-4.017 3.23-7.273 7.214-7.273zm-5.709 17.183l4.788 7.397h6.634l-7.457-13.418-3.965 6.021zm2.14 7.397L16.48 13.72 7.635 29.58h19.111zm8.702-17.307a5.172 5.172 0 01-3.728 4.98l-2.101-3.781a1.107 1.107 0 00-1.892-.072l-1.402 2.13a5.18 5.18 0 01-1.144-3.257c0-2.859 2.299-5.176 5.134-5.176 2.835 0 5.133 2.317 5.133 5.176z"
      clipRule="evenodd"
    />
    <path
      fill="#9FA6B2"
      d="M9.62 35.173c-.611 0-1.107.5-1.107 1.117s.496 1.116 1.107 1.116h24.42c.612 0 1.108-.5 1.108-1.116 0-.617-.496-1.117-1.107-1.117H9.62zm8.513 5.59c-.613 0-1.11.501-1.11 1.119 0 .617.497 1.118 1.11 1.118h7.396c.612 0 1.109-.5 1.109-1.118 0-.618-.497-1.119-1.11-1.119h-7.395z"
    />
    <path
      fill="#9FA6B2"
      fillRule="evenodd"
      d="M120.027 21.871c-.982-1.25-2.453-1.923-4.347-1.923-2.984 0-6.049 2.528-6.049 6.786 0 4.258 3.065 6.786 6.049 6.786 1.894 0 3.365-.66 4.347-1.923v1.058c0 2.445-1.472 3.929-4.142 3.929-1.594 0-3.107-.523-4.428-1.333l-1.036 2.432c1.376.989 3.515 1.525 5.464 1.525 4.36 0 7.003-2.541 7.003-6.677v-12.24h-2.861v1.58zm-7.467 4.863c0-2.225 1.444-4.08 3.855-4.08 2.303 0 3.857 1.773 3.857 4.08 0 2.308-1.554 4.08-3.857 4.08-2.411 0-3.855-1.867-3.855-4.08zm-8.219-4.849c-.899-1.168-2.248-1.937-4.101-1.937-3.65 0-6.526 2.898-6.526 6.923s2.875 6.924 6.526 6.924c1.854 0 3.202-.755 4.101-1.923v1.58h2.848v-13.16h-2.848v1.593zm-7.698 4.986c0-2.307 1.486-4.217 3.938-4.217 2.357 0 3.938 1.813 3.938 4.217s-1.581 4.218-3.938 4.218c-2.452 0-3.938-1.91-3.938-4.218zM138 26.858c-.013-4.107-2.52-6.91-6.172-6.91-3.842 0-6.499 2.803-6.499 6.924 0 4.176 2.766 6.924 6.676 6.924 1.976 0 3.774-.48 5.368-1.854l-1.417-2.048c-1.076.865-2.466 1.388-3.774 1.388-1.853 0-3.501-.99-3.883-3.353h9.647c.027-.329.054-.7.054-1.071zm-9.687-1.113c.3-1.923 1.43-3.242 3.46-3.242 1.813 0 2.998 1.195 3.311 3.242h-6.771z"
      clipRule="evenodd"
    />
    <path
      fill="#9FA6B2"
      d="M64.333 27.957l-5.546-13.738h-4.728v19.233h3.08V17.777L62.71 31.57h3.243l5.573-13.944v15.826h3.08V14.219h-4.729l-5.545 13.738zm16.871 5.495v-13.16h-2.861v13.16h2.861zm12.182-13.133c-.654-.261-1.322-.37-2.194-.37-1.594 0-2.93.576-3.788 1.826V20.29h-2.82v13.16h2.848v-7.24c0-2.238 1.294-3.53 3.106-3.53.695 0 1.567.165 2.166.481l.682-2.843zm-11.61-4.575c0-1.14-.886-2.033-2.017-2.033-1.13 0-1.99.893-1.99 2.033s.86 2.006 1.99 2.006c1.131 0 2.017-.866 2.017-2.006z"
    />
  </svg>
);

export default MirageLogo;
