import PropTypes from "prop-types"
import React from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { navigate } from "gatsby"

const Cta = ({ siteTitle }) => (
  <div className="bg-gray-800">
    <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center">
      <div className="lg:w-0 lg:flex-1">
        <h2
          className="text-3xl leading-9 font-extrabold tracking-tight text-white sm:text-4xl sm:leading-10"
          id="newsletter-headline"
        >
          <span className="text-5xl font-bold">2.</span> Get ahead of the curve{" "}
          <br className="hidden md:block" />
          with the
          <span className="text-mui-orange-600"> Chatbot Future Trends </span>
          ebook
        </h2>
        <p className="mt-3 max-w-3xl text-lg leading-6 text-gray-300">
          Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui Lorem
          cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat.
        </p>
      </div>
      <div className="mt-8 lg:mt-0 lg:ml-8">
        <form className="sm:flex" aria-labelledby="newsletter-headline">
          <input
            aria-label="Email address"
            type="email"
            required
            className="appearance-none w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out sm:max-w-xs"
            placeholder="Enter your email"
          />
          <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
            <button
              className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-mui-orange-500 hover:bg-blue-400 focus:outline-none focus:bg-indigo-400 transition duration-150 ease-in-out"
              onClick={e => {
                // To stop the page reloading
                e.preventDefault()
                // Lets track that custom click
                trackCustomEvent({
                  // string - required
                  category: "engagement",
                  // string - required
                  action: "download",
                  // string - optional
                  label: "Download Ebook – Download Button",
                })
                //... Other logic here
                navigate("#")
              }}
            >
              Download ebook
            </button>
          </div>
        </form>
        <p className="mt-3 text-sm leading-5 text-gray-300">
          We care about the protection of your data. Read our{" "}
          <a href="#" className="text-white font-medium underline">
            Privacy Policy.
          </a>
        </p>
      </div>
    </div>
  </div>
)

Cta.propTypes = {
  siteTitle: PropTypes.string,
}

Cta.defaultProps = {
  siteTitle: ``,
}

export default Cta
