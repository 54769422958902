import PropTypes from "prop-types"
import React from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { navigate } from "gatsby"

const BlogSection = ({ siteTitle }) => (
  <div
    id="blogSection"
    className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-32 lg:pb-28 lg:px-8"
  >
    <div className="absolute inset-0">
      <div className="bg-white h-1/3 sm:h-2/3"></div>
    </div>
    <div className="relative max-w-7xl mx-auto">
      <div className="text-center">
        <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
          <span className="text-5xl font-bold">3.</span> Learn how to build
          smarter chatbots
        </h2>
        <p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 sm:mt-4">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa libero
          labore natus atque, ducimus sed.
        </p>
      </div>
      <div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
          <div className="flex-shrink-0">
            <img
              className="h-48 w-full object-cover"
              src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
              alt=""
            />
          </div>
          <div className="flex-1 bg-white p-6 flex flex-col justify-between">
            <div className="flex-1">
              <p className="text-sm leading-5 font-medium text-blue-600">
                <a
                  href="#blogSection"
                  className="hover:underline"
                  onClick={e => {
                    // To stop the page reloading
                    e.preventDefault()
                    // Lets track that custom click
                    trackCustomEvent({
                      // string - required
                      category: "engagement",
                      // string - required
                      action: "blog_post_click",
                      // string - optional
                      label: "Blog – Tag click",
                    })
                    //... Other logic here
                    navigate("#blogSection")
                  }}
                >
                  Video
                </a>
              </p>
              <a
                href="#blogSection"
                className="block"
                onClick={e => {
                  // To stop the page reloading
                  e.preventDefault()
                  // Lets track that custom click
                  trackCustomEvent({
                    // string - required
                    category: "engagement",
                    // string - required
                    action: "blog_post_click",
                    // string - optional
                    label: "Blog – Article click",
                  })
                  //... Other logic here
                  navigate("#blogSection")
                }}
              >
                <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                  Up and running with Dialogflow
                </h3>
                <p className="mt-3 text-base leading-6 text-gray-500">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Architecto accusantium praesentium eius, ut atque fuga culpa,
                  similique sequi cum eos quis dolorum.
                </p>
              </a>
            </div>
            <div className="mt-6 flex items-center">
              <div className="flex-shrink-0">
                <a
                  href="#blogSection"
                  onClick={e => {
                    // To stop the page reloading
                    e.preventDefault()
                    // Lets track that custom click
                    trackCustomEvent({
                      // string - required
                      category: "engagement",
                      // string - required
                      action: "blog_post_click",
                      // string - optional
                      label: "Blog – Author Img click",
                    })
                    //... Other logic here
                    navigate("#blogSection")
                  }}
                >
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </a>
              </div>
              <div className="ml-3">
                <p className="text-sm leading-5 font-medium text-gray-900">
                  <a
                    href="#blogSection"
                    className="hover:underline"
                    onClick={e => {
                      // To stop the page reloading
                      e.preventDefault()
                      // Lets track that custom click
                      trackCustomEvent({
                        // string - required
                        category: "engagement",
                        // string - required
                        action: "blog_post_click",
                        // string - optional
                        label: "Blog – Author click",
                      })
                      //... Other logic here
                      navigate("#blogSection")
                    }}
                  >
                    Roel Aufderhar
                  </a>
                </p>
                <div className="flex text-sm leading-5 text-gray-500">
                  <time datetime="2020-03-16">Mar 16, 2020</time>
                  <span className="mx-1">&middot;</span>
                  <span>6 min read</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
          <div className="flex-shrink-0">
            <img
              className="h-48 w-full object-cover"
              src="https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80"
              alt=""
            />
          </div>
          <div className="flex-1 bg-white p-6 flex flex-col justify-between">
            <div className="flex-1">
              <p className="text-sm leading-5 font-medium text-blue-600">
                <a
                  href="#blogSection"
                  className="hover:underline"
                  onClick={e => {
                    // To stop the page reloading
                    e.preventDefault()
                    // Lets track that custom click
                    trackCustomEvent({
                      // string - required
                      category: "engagement",
                      // string - required
                      action: "blog_post_click",
                      // string - optional
                      label: "Blog – Tag click",
                    })
                    //... Other logic here
                    navigate("#blogSection")
                  }}
                >
                  Blog
                </a>
              </p>
              <a
                href="#blogSection"
                className="block"
                onClick={e => {
                  // To stop the page reloading
                  e.preventDefault()
                  // Lets track that custom click
                  trackCustomEvent({
                    // string - required
                    category: "engagement",
                    // string - required
                    action: "blog_post_click",
                    // string - optional
                    label: "Blog – Article click",
                  })
                  //... Other logic here
                  navigate("#blogSection")
                }}
              >
                <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                  How to use search engine optimization to drive sales
                </h3>
                <p className="mt-3 text-base leading-6 text-gray-500">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
                  facilis asperiores porro quaerat doloribus, eveniet dolore.
                  Adipisci tempora aut inventore optio animi., tempore
                  temporibus quo laudantium.
                </p>
              </a>
            </div>
            <div className="mt-6 flex items-center">
              <div className="flex-shrink-0">
                <a
                  href="#blogSection"
                  onClick={e => {
                    // To stop the page reloading
                    e.preventDefault()
                    // Lets track that custom click
                    trackCustomEvent({
                      // string - required
                      category: "engagement",
                      // string - required
                      action: "blog_post_click",
                      // string - optional
                      label: "Blog – Author Img click",
                    })
                    //... Other logic here
                    navigate("#blogSection")
                  }}
                >
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </a>
              </div>
              <div className="ml-3">
                <p className="text-sm leading-5 font-medium text-gray-900">
                  <a
                    href="#blogSection"
                    className="hover:underline"
                    onClick={e => {
                      // To stop the page reloading
                      e.preventDefault()
                      // Lets track that custom click
                      trackCustomEvent({
                        // string - required
                        category: "engagement",
                        // string - required
                        action: "blog_post_click",
                        // string - optional
                        label: "Blog – Author click",
                      })
                      //... Other logic here
                      navigate("#blogSection")
                    }}
                  >
                    Brenna Goyette
                  </a>
                </p>
                <div className="flex text-sm leading-5 text-gray-500">
                  <time datetime="2020-03-16">Mar 16, 2020</time>
                  <span className="mx-1">&middot;</span>
                  <span>6 min read</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
          <div className="flex-shrink-0">
            <img
              className="h-48 w-full object-cover"
              src="https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80"
              alt=""
            />
          </div>
          <div className="flex-1 bg-white p-6 flex flex-col justify-between">
            <div className="flex-1">
              <p className="text-sm leading-5 font-medium text-blue-600">
                <a
                  href="#blogSection"
                  className="hover:underline"
                  onClick={e => {
                    // To stop the page reloading
                    e.preventDefault()
                    // Lets track that custom click
                    trackCustomEvent({
                      // string - required
                      category: "engagement",
                      // string - required
                      action: "blog_post_click",
                      // string - optional
                      label: "Blog – Tag click",
                    })
                    //... Other logic here
                    navigate("#blogSection")
                  }}
                >
                  {" "}
                  Case Study
                </a>
              </p>
              <a
                href="#blogSection"
                className="block"
                onClick={e => {
                  // To stop the page reloading
                  e.preventDefault()
                  // Lets track that custom click
                  trackCustomEvent({
                    // string - required
                    category: "engagement",
                    // string - required
                    action: "blog_post_click",
                    // string - optional
                    label: "Blog – Article click",
                  })
                  //... Other logic here
                  navigate("#blogSection")
                }}
              >
                <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                  Improve your customer experience
                </h3>
                <p className="mt-3 text-base leading-6 text-gray-500">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint
                  harum rerum voluptatem quo recusandae magni placeat saepe
                  molestiae, sed excepturi cumque corporis perferendis hic.
                </p>
              </a>
            </div>
            <div className="mt-6 flex items-center">
              <div className="flex-shrink-0">
                <a
                  href="#blogSection"
                  onClick={e => {
                    // To stop the page reloading
                    e.preventDefault()
                    // Lets track that custom click
                    trackCustomEvent({
                      // string - required
                      category: "engagement",
                      // string - required
                      action: "blog_post_click",
                      // string - optional
                      label: "Blog – Author Img click",
                    })
                    //... Other logic here
                    navigate("#blogSection")
                  }}
                >
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </a>
              </div>
              <div className="ml-3">
                <p className="text-sm leading-5 font-medium text-gray-900">
                  <a
                    href="#blogSection"
                    className="hover:underline"
                    onClick={e => {
                      // To stop the page reloading
                      e.preventDefault()
                      // Lets track that custom click
                      trackCustomEvent({
                        // string - required
                        category: "engagement",
                        // string - required
                        action: "blog_post_click",
                        // string - optional
                        label: "Blog – Author click",
                      })
                      //... Other logic here
                      navigate("#blogSection")
                    }}
                  >
                    Daniela Metz
                  </a>
                </p>
                <div className="flex text-sm leading-5 text-gray-500">
                  <time datetime="2020-03-16">Mar 16, 2020</time>
                  <span className="mx-1">&middot;</span>
                  <span>6 min read</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

BlogSection.propTypes = {
  siteTitle: PropTypes.string,
}

BlogSection.defaultProps = {
  siteTitle: ``,
}

export default BlogSection
