import PropTypes from "prop-types"
import React from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { navigate } from "gatsby"

const CtaSplit = ({ siteTitle }) => (
  <>
    <div className="bg-mui-orange-500">
      <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-24 lg:px-8">
        {/*<h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-5xl">
          <span classNameName="text-gray-900">Ready to dive in?</span>
          <br />
          Here are <span classNameName="text-mui-orange-600">5 steps</span> towards
          <br />
          hassle free WordPress chatbots
</h2>*/}
        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-white sm:text-5xl sm:leading-none md:text-6xl">
          Ready to dive in?
        </h2>
        <h2 className="text-3xl mt-8 leading-9 font-extrabold tracking-tight text-gray-800 sm:text-4xl sm:leading-10">
          Here are <span className="text-5xl font-bold text-gray-50">5</span>
          <span className="text-gray-50"> steps</span> towards <br /> hassle
          free WordPress chatbots
        </h2>
      </div>
    </div>

    <div className="bg-gray-50">
      <main className="mx-auto max-w-screen-xl px-4 sm:px-6 sm:py-8 md:py-24">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
              <button
                type="button"
                className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline"
              >
                <img
                  className="w-full"
                  src="https://images.unsplash.com/photo-1556740758-90de374c12ad?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                  alt="Woman making a sale"
                />
                <div className="absolute inset-0 w-full h-full flex items-center justify-center">
                  <svg
                    className="h-20 w-20 text-blue-500"
                    fill="currentColor"
                    viewBox="0 0 84 84"
                  >
                    <circle opacity="0.9" cx="42" cy="42" r="42" fill="white" />
                    <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left my-12">
            <h2 className="text-gray-900 text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
              <span className="text-5xl font-bold">1.</span> Watch this success
              story
            </h2>
            <p className="mt-3 text-lg leading-7 text-gray-500">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et,
              egestas tempus tellus etiam sed. Quam a scelerisque amet
              ullamcorper eu enim et fermentum, augue. Aliquet amet volutpat
              quisque ut interdum tincidunt duis.
            </p>
            <div className="mt-8">
              <div className="inline-flex rounded-md shadow">
                <a
                  href="#blogSection"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                  onClick={e => {
                    // To stop the page reloading
                    e.preventDefault()
                    // Lets track that custom click
                    trackCustomEvent({
                      // string - required
                      category: "engagement",
                      // string - required
                      action: "cta_click",
                      // string - optional
                      label: "Learn more – Success story CTA",
                    })
                    //... Other logic here
                    navigate("#blogSection")
                  }}
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </>
)

CtaSplit.propTypes = {
  siteTitle: PropTypes.string,
}

CtaSplit.defaultProps = {
  siteTitle: ``,
}

export default CtaSplit
