import PropTypes from "prop-types"
import React from "react"
import HeroImageTwo from "../images/hero-image-two.js"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { navigate } from "gatsby"

const Hero = ({ siteTitle }) => (
  <div className="relative bg-white overflow-hidden">
    <div className="max-w-screen-xl mx-auto ">
      <div className="relative z-40 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
        <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
          <div className="sm:text-center lg:text-left">
            <div className="text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
              Coming soon
            </div>
            <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-5xl">
              The best chatbots are built on solid
              <span className="text-mui-orange-600"> groundwork</span>
            </h2>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              Introducing an all-inclusive toolset to integrate Dialogflow
              chatbots with your WordPress site or WooCommerce store.
            </p>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a
                  href="#pricing"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                  onClick={e => {
                    // To stop the page reloading
                    e.preventDefault()
                    // Lets track that custom click
                    trackCustomEvent({
                      // string - required
                      category: "engagement",
                      // string - required
                      action: "cta_click",
                      // string - optional
                      label: "Hero Button – Get Started",
                    })
                    //... Other logic here
                    navigate("#pricing")
                  }}
                >
                  Get started for free
                </a>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <a
                  href="#contactForm"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-blue-700 bg-blue-100 hover:text-blue-600 hover:bg-blue-50 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                  onClick={e => {
                    // To stop the page reloading
                    e.preventDefault()
                    // Lets track that custom click
                    trackCustomEvent({
                      // string - required
                      category: "engagement",
                      // string - required
                      action: "cta_click",
                      // string - optional
                      label: "Hero Button – Try Demo",
                    })
                    //... Other logic here
                    navigate("#contactForm")
                  }}
                >
                  Try demo
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      {/*
      <svg
        className="hidden lg:block absolute right-0 inset-y-0 h-full w-full text-white"
        fill="#1a56db"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <polygon points="0,0 35,100 100,100 100,0" />
      </svg>
      */}
      <HeroImageTwo className="lg:absolute h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full px-16 lg:py-4 md:py-0" />
    </div>
  </div>
)

Hero.propTypes = {
  siteTitle: PropTypes.string,
}

Hero.defaultProps = {
  siteTitle: ``,
}

export default Hero
