import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Hero from "../components/hero"
import Feature from "../components/feature"
import Cta from "../components/cta"
import Testimonial from "../components/testimonial"
import Footer from "../components/footer"
import LogoCloud from "../components/logoCloud"
import CtaSplit from "../components/ctaSplit"
import BlogSection from "../components/blogSection"
import Newsletter from "../components/newsletter"
import Community from "../components/community"
import ContactForm from "../components/contactForm"
import HeaderBanner from "../components/headerBanner"
import Pricing from "../components/pricing"

const IndexPage = () => (
  <Layout>
    <HeaderBanner />
    <Header />
    <Hero />
    <LogoCloud />
    <Feature />
    <CtaSplit />
    <Cta />
    <BlogSection />
    <Pricing />
    <Community />
    <Testimonial />
    <ContactForm />

    <Footer />
  </Layout>
)

export default IndexPage
