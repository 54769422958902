import PropTypes from "prop-types"
import React from "react"
const HeaderBanner = ({ siteTitle }) => (
  <div className="relative bg-mui-orange-700">
    <div className="max-w-screen-xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div className="pr-16 sm:text-center sm:px-16">
        <p className="font-medium text-white">
          <span className="md:hidden">
            This site is currently under construction.
          </span>
          <span className="hidden md:inline">
            This site is currently under construction. We're happy to welcome
            you back soon!
          </span>
          <span className="block sm:ml-2 sm:inline-block">
            <a
              className="text-mui-orange-200 font-bold underline"
              href="javascript:gaOptout();"
            >
              Opt-out of Google Analytics
            </a>
          </span>
        </p>
      </div>
    </div>
  </div>
)

HeaderBanner.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderBanner.defaultProps = {
  siteTitle: ``,
}

export default HeaderBanner
