import React from "react"

const HeroImageTwo = props => (
  <svg x="0px" y="0px" viewBox="0 0 947.47 1030.48" {...props}>
    <style>
      {
        ".heroImgTwo0{fill:#d4edff}.heroImgTwo1{fill:#fff}.heroImgTwo23{fill:#e9f6ff}.heroImgTwo114{fill:#8dcfff}.heroImgTwo115{fill:#0063cb}.heroImgTwo116{fill:#e9d6b8}.heroImgTwo117{fill:#163f59}.heroImgTwo118{fill:#161711}.heroImgTwo119{fill:#e2c59c}.heroImgTwo120{fill:#b0bfde}.heroImgTwo122{fill:#387bff}.heroImgTwo123{fill:#fec00f}.heroImgTwo126{fill:#7dc2f5}.heroImgTwo129{fill:#73a9ff}.heroImgTwo131{fill:#fa0}.heroImgTwo142{fill:#194866}.heroImgTwo144{fill:#4e99ff}.heroImgTwo145{fill:#2b83fd}.heroImgTwo152{fill:#d8e5ed}.heroImgTwo155{fill:#0095ef}.heroImgTwo157{fill:#deccaf}.heroImgTwo158{fill:#f99b1c}.heroImgTwo159{fill:#0253a2}.heroImgTwo160{fill:#e9dbc4}.heroImgTwo164{fill:#e4eef3}.heroImgTwo166{fill:#b6c2c9}.heroImgTwo167{fill:#879ba6}.heroImgTwo170{fill:#cdd5d7}.heroImgTwo171{fill:#de3f18}.heroImgTwo174{fill:#2f3844}"
      }
    </style>
    <g id="Layer_7">
      <path
        className="heroImgTwo23"
        d="M733.42 926.31c-66.46-.58-272.39-3.23-397.38-11.82-231.76-15.92-420.98 11.37-296.6 41.96 34.76 8.55-65.36 60.3 179.95 71.55 215.34 9.87 434.81-12.39 541.03-21.97 106.22-9.58 155.22-26.34 177.97-33.9 20.01-6.64 27.33-43.79-204.97-45.82z"
      />
      <path
        className="heroImgTwo131"
        d="M646.2 142.76c-67.7-2.47-129.93 14.5-172.47 36.78-42.93 22.71-66.17 50.72-64.92 80.14 1.26 29.7 14.16 86.48 48.75 113.68 34.94 26.77 344.18 64.17 373.49-45.88C855.87 234.34 785 145.07 646.2 142.76z"
      />
      <path
        className="heroImgTwo131"
        d="M575.32 344.08s2.62 58.34-72.05 100.56c0 0 31.31-85.15-9.85-120.33-24.7-21.12 81.9 19.77 81.9 19.77z"
      />
      <path
        className="heroImgTwo1"
        d="M567.35 270.92c0 7.28-5.9 13.18-13.18 13.18-7.28 0-13.18-5.9-13.18-13.18 0-7.28 5.9-13.17 13.18-13.17 7.28-.01 13.18 5.89 13.18 13.17zM630.86 270.92c0 7.28-5.9 13.18-13.17 13.18-7.28 0-13.18-5.9-13.18-13.18 0-7.28 5.9-13.17 13.18-13.17 7.27-.01 13.17 5.89 13.17 13.17zM694.37 270.92c0 7.28-5.9 13.18-13.18 13.18-7.28 0-13.18-5.9-13.18-13.18 0-7.28 5.9-13.17 13.18-13.17 7.28-.01 13.18 5.89 13.18 13.17z"
      />
      <path
        className="heroImgTwo122"
        d="M371.17 549.88c-48.9 1.65-129.32-3.18-161.98-14.86-32.98-11.94-38.18-101.63-16.16-124.55 22.31-22.65 84.87-29.58 115.99-31.55 77.54-4.92 147.1.98 134.96 69.88-12.6 71.49-19.89 99.29-72.81 101.08z"
      />
      <path
        className="heroImgTwo122"
        d="M224.72 528.01s-1.35 27.23 33.4 47.1c0 0-14.42-39.81 4.87-56.14 11.58-9.8-38.27 9.04-38.27 9.04z"
      />
      <path
        className="heroImgTwo115"
        d="M432.45 876.59c-66.15 6.28-189.47 8.72-228.05-14.99-38.9-24.16-8.12-149.2 30.85-175.73 39.24-26.08 183.24-18.98 225.51-14.48 105.35 11.23 48.68 197.9-28.31 205.2z"
      />
      <path
        className="heroImgTwo115"
        d="M432.86 856.1s-2.86 40.05-57.22 62.95c0 0 28.17-55.66 2.86-82.97-15.2-16.39 54.36 20.02 54.36 20.02z"
      />
      <path
        className="heroImgTwo126"
        d="M651.32 572.42c-62.6-33.2-125.24-36.37-181-21.71-55.77 14.66-77.11 28.34-69.21 94.9 7.9 66.56 30 104.45 61.59 103.2 90.17-3.57 113.02-5.62 160.37-1.65l-15.12 34.49L646.09 749c47.42 1.77 54.61-19.95 57.6-37.08 0 0 10.23-106.3-52.37-139.5z"
      />
      <path
        className="heroImgTwo144"
        d="M558.18 977.22l.32 7.32h5.95s.33-.91.84-.91c.42 0 .97.91 1.55.91H581.99s.27-1.35-.06-1.82c-.17-.24-1.17-.66-1.83-.86-3.08-.96-11.88-3.93-13.42-7.18-1.85-3.95-8.5 2.54-8.5 2.54z"
      />
      <path
        className="heroImgTwo152"
        d="M582.04 983c.13.58-.05 1.54-.05 1.54H566.84c-.58 0-1.13-.91-1.55-.91-.5 0-.84.91-.84.91h-5.95l-.27-1.54h23.81zM571.01 980.14c.13.19.47-.03.92-.41 1.09-.92.98-.81.7-.97-.2-.1-.43-.21-1.05.34-.43.4-.7.85-.57 1.04zM569.4 979.36c.13.19.48-.03.93-.4 1.09-.92.99-.82.71-.97-.2-.11-.43-.21-1.05.34-.46.39-.72.84-.59 1.03zM567.92 978.47c.13.19.48-.03.92-.41 1.09-.92.99-.82.71-.96-.19-.1-.43-.21-1.05.34-.44.39-.71.84-.58 1.03z"
      />
      <path
        className="heroImgTwo144"
        d="M513.03 984.6c-.45.76-.14 2.55-.14 2.55s9.16 1.12 12.11.36c.77-.2 2.06-.93 3.28-1.7.21-.13.57-.91.77-1.04.37-.24.56.17.87-.05.82-.57 1.39-1.01 1.4-1.07.81-4.64-.27-6.51-.27-6.51s-5.87-2.86-7.33-.87c-3.43 4.7-9.4 6.12-10.69 8.33z"
      />
      <path
        className="heroImgTwo152"
        d="M512.77 985.59c2.83.4 8.76 1.2 9.86.98 4.47-.89 7.15-3.49 8.96-5.09-.04.64-.12 1.36-.26 2.17-.01.06-.59.5-1.4 1.07-.32.22-.51-.19-.87.05-.2.13-.56.91-.77 1.04-1.22.77-2.51 1.5-3.28 1.7-2.95.76-12.11-.36-12.11-.36s-.17-.81-.13-1.56zM517.75 981.19c-.04.19.34.3.85.28 1.04-.06 2.09 0 3.15.17.52.08.97.01.99-.2.02-.22-.38-.49-.9-.56-1.04-.16-2.09-.21-3.11-.14-.5.03-.94.25-.98.45zM519.51 979.81c-.03.2.36.35.86.37 1.03.04 2.07.2 3.11.46.52.13.95.09.96-.13.01-.22-.39-.54-.89-.66-1.03-.25-2.06-.4-3.08-.42-.49-.01-.93.18-.96.38zM521.22 978.55c-.03.21.36.41.87.47 1.02.13 2.05.38 3.06.74.51.18.93.17.94-.06.01-.23-.39-.58-.88-.75-1.01-.35-2.03-.58-3.04-.7-.5-.06-.92.09-.95.3z"
      />
      <path
        className="heroImgTwo116"
        d="M574.38 801.06l-.97-5.64-.33-3.23.19-4.34s1.32-1.73 1.92 2.39c.09.62.98-4.22.98-4.22l1-3.34 1.22-.51 2.75 1.72s-.25 1.53-2.49.76c-.24-.09-.37 2.79-.37 2.79l2.93 4.26s.57 2.23-.3 3.21l-.88.98-.36-.39-.21 4.29s-.35 1.69-1.79 2.39c-1.44.7-.61 2.31-.61 2.31l-2.68-3.43z"
      />
      <path
        className="heroImgTwo155"
        d="M568.54 833.71s-5.74-11.52-4.34-15.76c1.41-4.23 7.39-19.77 7.39-19.77l7.38 4.03s-5.23 30.55-6.89 32.62c-1.66 2.07-3.09-.47-3.54-1.12z"
      />
      <path
        className="heroImgTwo117"
        d="M569.99 976.84l-12.15.41 2.94-127.27 25.78-7.73s-4.05 82.95-16.57 134.59z"
      />
      <path
        className="heroImgTwo142"
        d="M550.83 852.41L519.07 979.29 533.43 979.7 580.3 844.69z"
      />
      <path
        className="heroImgTwo142"
        d="M560.76 850.83l2.06-1.47 23.74-7.12s-.41 8.43-1.38 21.58l-25.06 14.47.64-27.46z"
      />
      <path
        className="heroImgTwo117"
        d="M550.72 852.85l.12-.44 14.42-3.78 21.3-6.38s-.07 1.5-.23 4.23l-35.61 6.37z"
      />
      <path
        d="M535.86 785.32s13.88-9.76 26.4 6.51c12.52 16.26 25.61 50.18 25.61 50.18l-38.13 12.44-12.4-49.34c-.01 0-6.89-16.54-1.48-19.79z"
        fill="#007fcb"
      />
      <path
        className="heroImgTwo155"
        d="M535.73 786s-4.3-.4-2.46 12.6c1.84 13.01 6.38 38.36 8.47 44.73.52 1.58 2.58 8.94 5.89 4.47 3.31-4.47 5.52-21.69-.12-43.91-3.61-14.25-9.57-17.89-11.78-17.89z"
      />
      <path
        className="heroImgTwo118"
        d="M585.08 807.67L585.79 808.51 583.03 817.19 577.5 818.84 576.22 817.66 578.33 809.86z"
      />
      <path
        className="heroImgTwo116"
        d="M566.02 819.19l8.08-4.69 3.19-.47-.85 2.82 3.18 1.17 3.19-1.17 1.49-2.11.63 2.34s-.21 1.18-1.06 2.11c-.85.94-3.82 6.11-3.82 6.11l-2.34-.47-5.31-1.41L569 826l-2.98-6.81z"
      />
      <path
        className="heroImgTwo155"
        d="M543.96 845.64s1.72-13.01 5.16-15.45c3.44-2.44 16.7-10.84 16.7-10.84l3.68 8.13s-20.87 20.87-23.33 21.42c-2.46.53-2.21-2.45-2.21-3.26z"
      />
      <g>
        <path
          className="heroImgTwo116"
          d="M558.15 779.53c-2.34 6.81-3.83 10.13-4.21 10.47-2.63 2.34-10.42-3.71-9.44-5.4.98-1.69 2.47-10.22 2.42-11.56-.07-1.33 12.61 2.45 11.23 6.49z"
        />
        <path
          className="heroImgTwo119"
          d="M553.82 774.63c-2.27-.98-4.57-1.65-5.85-1.8-.11 2.28-.15 4.54-.05 6.06.28 4.23 3.48 6.49 5.46 8.21.43.37.99.69 1.62.96.53-1.14 1.47-3.63 3.15-8.53.62-1.84-1.66-3.62-4.33-4.9z"
        />
        <path
          className="heroImgTwo116"
          d="M564.56 761.23l-.81 19.53s-.19 4.63-2.04 5.15c-1.83.51-6.69-.23-8.67-1.94-1.98-1.72-5.18-3.98-5.46-8.21-.28-4.24.58-14.24.58-14.24s1.61-3.19 7.87-.67c6.28 2.52 8.53.38 8.53.38z"
        />
        <path
          className="heroImgTwo142"
          d="M564.07 756.43c.87.31 2.31 1.09 2.6 2.59.3 1.53-1.22 3.65-2.58 3.85-1.35.21-9.96-.46-11-.92-1.03-.46-3.01-1.04-3.86-2.71-.55-1.08 2.8-3.04 4.7-2.33 4.85 1.82 6.92-1.62 10.14-.48z"
        />
        <path
          className="heroImgTwo142"
          d="M551.44 759.52l-.35 8.45-.86 4.41-1.02-.08-1.87-.16s-2.58-11.85.36-12.93c2.95-1.08 3.6-.36 3.74.31z"
        />
        <path
          className="heroImgTwo116"
          d="M549.06 773.12c-.03.73-.66 1.27-1.41 1.21l-.79-.07c-.75-.07-1.34-.71-1.31-1.44l.13-3.09c.03-.73.67-1.28 1.42-1.21l.79.07c.75.06 1.34.7 1.3 1.44l-.13 3.09z"
        />
        <path
          className="heroImgTwo117"
          d="M553.54 759.27L550.37 758.64 548.87 767.87 552.04 768.5z"
        />
        <path
          className="heroImgTwo116"
          d="M552.4 766.31c.47-2.88 2.96-4.8 5.58-4.28l2.44.48c2.61.51 3.63 3.13 3.16 6.02-.47 2.88-2.97 4.8-5.58 4.28l-1.72-.34c-2.61-.52-4.35-3.28-3.88-6.16z"
        />
      </g>
      <g>
        <path
          className="heroImgTwo126"
          d="M386.08 215.02c-21.09-.75-40.54 5.79-53.85 14.28-13.44 8.65-20.76 19.24-20.44 30.3.31 11.17 4.19 32.49 14.9 42.63 10.82 9.98 107.12 23.25 116.54-18.21 7.97-35.1-13.89-68.49-57.15-69z"
        />
        <path
          className="heroImgTwo126"
          d="M374.42 291.35s.98 21.95-27.1 37.83c0 0 11.78-32.03-3.71-45.27-9.29-7.94 30.81 7.44 30.81 7.44z"
        />
      </g>
      <g>
        <path
          className="heroImgTwo117"
          d="M550.68 452.75c11.6-.36 10.72 5.29 11.17 12.64.46 7.35-2.89 22.45-10.11 25.95-7.22 3.5-10.18 12.56-12.75 6.44-2.99-7.13-6.71-2.61-10.21-6.03-6.16-6.01-4.28-13.9 2.55-18.86 6.82-4.96 6.64-6.61 7.41-11.5.78-4.91 8.32-8.52 11.94-8.64z"
        />
        <path
          className="heroImgTwo157"
          d="M549.99 482.18c-2.41-.36-15.1 10.88-18.43 13.94-3.33 3.07-6.75 7.3-3.23 7.28 3.52-.01 7.19-3 9.99-5.01s6.56-4.98 8.64-6.3c2.08-1.33 5.44-9.54 3.03-9.91z"
        />
        <path
          className="heroImgTwo157"
          d="M532.23 506.23s-1.54-9.17-6.57-15.98c-5.04-6.82-7.03-11.67-7.03-11.67l-2.71.45s7.57 18.15 9.34 21.11c1.78 2.97 1.89 2.56 6.97 6.09zM509.22 473.77l-.32-2.9s1.01.81 1.13 1.85l.22 2-1.03-.95zM511.5 475.69l-.66-1.83.98-1.53s.53.17.19 1.7c-.08.34.72 2.33.72 2.33l-1.23-.67z"
        />
        <path
          className="heroImgTwo157"
          d="M518.05 480.63s-3.59-1.2-7.11-3.78c-3.51-2.58-2.92-3.44-3.51-4.67-.6-1.23-1.59-2.87-.33-1.91 1.26.97 2.34 3.41 2.97 3.95.64.53 1.82.8 2.91 1.26 1.09.46 1.83.9 1.03-.04-.79-.94-1.06-1.25-1.22-1.66-.16-.41-.46-1.02-.03-1.29.43-.27.76.64 1.03.95.27.31 2.12 1.41 2.5 1.73.38.32 1.13 1.86 1.69 2.78.56.93 2.06 4.83 1.33 5.37-.01 0-.18-2.23-1.26-2.69z"
        />
        <path
          className="heroImgTwo116"
          d="M465.99 580.12s-2.3 5.72-3.87 8.35c-1.57 2.62-2.91 4.38-3.56 6.72-.65 2.34 8.72-.54 9.78-2.08 1.06-1.53 1.27-6.73 5.02-10.63 3.75-3.9-7.37-2.36-7.37-2.36z"
        />
        <path
          className="heroImgTwo158"
          d="M506.11 528.72s-28.15 11.45-30.95 14.83c-2.8 3.37-7.61 29.56-9.61 36.59-.42 1.48 6.35 3.52 8.25 2.46 1.9-1.06 10.23-16.46 11.61-20.45 1.52-4.41.97-9.69 2.17-10.19 2.52-1.05 27.56-7.32 31.14-9.71 3.58-2.4 13.96-10.9 12.88-13.69-1.09-2.81-25.49.16-25.49.16z"
        />
        <path
          className="heroImgTwo115"
          d="M470.54 586.62s1.84 2.31 2.3 3.32c.46 1.01 1.59 1.99.71 3.04-.88 1.04-4.28 3.24-6.89 6.86-2.61 3.62-7.18 7.78-10.2 9.16-3.02 1.38-7.46.97-7.48-.56-.02-1.53 2.42-3.64 3.65-4.46 1.24-.81 6.18-10.76 6.83-12.3.65-1.55 1.6-2.3 2.85-1.59 1.26.72 2.83 1.18 4.9-.3 2.09-1.5 3.33-3.17 3.33-3.17z"
        />
        <path
          className="heroImgTwo159"
          d="M467.22 589.77c-2.08 1.48-3.65 1.02-4.9.3-1.25-.71-2.2.04-2.85 1.59-.65 1.55-5.6 11.49-6.83 12.3-1.24.81-3.67 2.93-3.65 4.46 0 .07.02.14.04.21 1.41.64 4.43.54 6.7-.52 3.06-1.43 7.73-5.65 10.41-9.3 2.68-3.65 6.14-5.9 7.04-6.95.23-.26.32-.52.34-.77-.22-.39-.5-.78-.68-1.17-.46-1.01-2.3-3.32-2.3-3.32s-1.24 1.69-3.32 3.17z"
        />
        <path
          className="heroImgTwo131"
          d="M501.46 536.11c-.95-.98-2.48-2.98-3.28-4.07-8.55 3.66-21.19 9.3-23.02 11.51-2.8 3.37-7.61 29.56-9.61 36.59-.16.56.73 1.21 1.99 1.73 1.08-2.51 2.36-5.62 3.81-7.56 2.73-3.65 5.14-10.47 7.7-16.18 2.56-5.71.05-10.09 3.53-11.88 3.47-1.79 20.22-8.76 18.88-10.14z"
        />
        <path
          className="heroImgTwo159"
          d="M551.35 505.94s-4.52 4.64-5.94 9.08c-1.42 4.44 1.89 16.37-6.08 25.01-4.06 4.39-27.61 2.08-27.61 2.08 19.12-30.84 20.24-35.8 19.12-39.4-1.13-3.6-.08-5.33 3.6-8.11 3.69-2.78 6.37-5.31 7.18-8.55.8-3.24 29.84-9.12 9.73 19.89z"
        />
        <path
          className="heroImgTwo160"
          d="M559.17 485.13s-1.49-1.18-1.76-6.16-.38-7.58.66-9.35c1.03-1.77-9.58-2.13-9.81 1.53-.24 3.65-.86 4.15.24 8 1.1 3.85-2.72 4.92-3.52 5.23-1.69.65-6.48 6.31 14.19.75z"
        />
        <path
          className="heroImgTwo160"
          d="M550.8 464.47c1.91.42 3.78.58 5.53.18-1.42 8.57-9.27 9.69-9.54 9.71-.29.02-.57.04-.94-.32-.37-.37-.57-.82-.58-1.07 0-.25.16-.55.08-.57-.09-.02-.67-.16-.82-.2-.15-.03-.3-.41-.3-.41s.27-.49.24-.5c-.76-.18-.93-.5-1.01-.67-.08-.18.08-.52.23-.81.12-.25-.08-.82-.22-1.01-.14-.19-.88-.3-1.06-.47-.18-.17-.4-.53-.34-.64.06-.11.31-.43.79-1.05.48-.63.94-2 .31-4-.63-2.01-.19-2.52-.01-3.07.21-.61 1.32 3.51 7.64 4.9z"
        />
        <path
          className="heroImgTwo142"
          d="M547.97 453.78c.98-.4 10.7-1.99 15.35 2.32s5.9 10.76 3.67 16.74c-2.23 5.97-6.1 10.67-1 13.8 16.37 10.06 7.8 14.15 4.81 17.58-5.79 6.63-19.67 5.61-20.13 1.05-.57-5.67 10.47-15.78 4.2-19.49-4.65-2.75-6.1-4.2-4.98-10.87 1.12-6.67 7.88-8.77 3.87-10.12-4.73-1.6-14.26-7.57-5.79-11.01z"
        />
        <path
          className="heroImgTwo160"
          d="M554.95 487.62c-3.39.65-13.85 19.43-16.86 24.56-3.01 5.13-5.6 11.68-.91 10.08 4.7-1.6 8.22-6.79 11.01-10.43 2.8-3.64 6.44-8.84 8.6-11.35 2.15-2.52 1.56-13.52-1.84-12.86z"
        />
        <g>
          <path
            className="heroImgTwo116"
            d="M501.62 572.03s3.58 5.5 3.77 7.52c.19 2.01.45 3.39 2.19 2.96 1.74-.42 4.4-5.06 4.01-6.55-.4-1.49-4.48-4.97-5.09-7.32-.61-2.36-4.92 4.29-4.92 4.29l.04-.9z"
          />
          <path
            className="heroImgTwo115"
            d="M508.82 571.94s2.39 1.1 3.84 1.25c1.44.15 3.48 1.47 3.27 4.26-.21 2.79-.81 8.37-.76 11.89.05 3.52-3.24 9.32-4.13 10.5-1.42 1.88-4.68 1.88-5.41.24-.73-1.65.15-6.3.21-8.69.06-2.39-.98-10.2-1.07-11.14-.09-.95 2.66-3.02 3.63-1.88.97 1.15 1.89-.18 1.9-1.04 0-.86-1.48-5.39-1.48-5.39z"
          />
          <path
            className="heroImgTwo159"
            d="M514.67 577.17c.16-2.22-1.09-3.51-2.34-4.02-.32-.06-.67-.15-1.03-.26-.72-.09-1.65-.39-2.4-.68.32.98 1.4 4.38 1.38 5.12-.01.86-.93 2.19-1.9 1.04l-.01-.01c-.34.25-.78.26-1.23-.26-1.13.28-2.45 1.49-2.39 2.15.09.94 1.13 8.75 1.07 11.14-.05 2.39-.94 7.05-.21 8.69.18.41.52.71.94.91 1.15.04 2.45-.42 3.22-1.44.89-1.18 4.19-6.98 4.13-10.5-.04-3.51.56-9.1.77-11.88z"
          />
          <path
            className="heroImgTwo123"
            d="M521.76 527.91c-5.96.06-33.36-1.99-37.23 1.3-3.88 3.3 2.5 12.72 22.49 17.15 19.99 4.42 23.81 3.17 30.19-3.16 6.38-6.34-11.01-15.34-15.45-15.29z"
          />
          <path
            className="heroImgTwo123"
            d="M493.02 536.9s10.63 8.76 11.44 18.47c.81 9.71 2.9 14.21 2.9 14.21l-5.78 3.35s-17.74-27.13-19.03-32.6c-1.29-5.47-.84-11.36 3.06-10.46 3.89.89 7.41 7.03 7.41 7.03z"
          />
          <path
            d="M501.55 572.88c.02.03.03.04.03.04l5.05-2.93-5.08 2.89z"
            fill="#89d5da"
          />
          <path
            className="heroImgTwo131"
            d="M490.3 538.88c3.89 3.87 10.92 8.99 12.51 18.03 1.36 7.7 3.28 11.99 3.83 13.09l.72-.42s-2.09-4.5-2.9-14.21-11.76-17.16-11.76-17.16-4.8-1.72-2.4.67z"
          />
          <path
            className="heroImgTwo131"
            d="M521.76 527.91h-.83c2.47 1.07 5.66 2.5 6.91 3.24 2.17 1.29 1.57 3.53-2.82 4.63s-20.15 1.53-24.5.18c-4.35-1.35-12.27-2.9-11.67-.92.22.74 2.88 1.71 3.38 2.54 2.4 1.71 2.38 4.93 6.44 6.39 2.46.89 5.24 1.7 8.35 2.38 19.99 4.42 23.81 3.17 30.19-3.16 2.35-2.33 3.89-4.83 4.73-7.48 1.43-4.53-15.74-7.85-20.18-7.8z"
          />
        </g>
        <g>
          <path
            className="heroImgTwo119"
            d="M509.19 524.42s-.11-.7-.66-.65c-.91.09-2.51.98-3.06 1.33-.62.38-3.23 2.67-3.23 2.67s1.29-.03 2.34-.95c1.54-1.33 4.61-2.4 4.61-2.4z"
          />
          <path
            className="heroImgTwo119"
            d="M508.76 524.64c-1.3.18-2.36 1.27-3.3 2.85-.89 1.5-2.01 1.61-2.01 1.61s.47-1.03 2.1-3.21c.46-.61.99-1.25 1.64-1.65 1.93-1.21 4.91-.07 1.57.4z"
          />
          <path
            className="heroImgTwo160"
            d="M535.8 522.54c-6.31.45-16.39.64-16.39.64s-13.95 1.92-.81-2.88c2.84-1.03 10.28-4.41 20.77-5.65 1.15-.14.56.14 1.77.59 2.09.77-.22 6.93-5.34 7.3z"
          />
          <path
            className="heroImgTwo160"
            d="M507.58 525.47l-.02-.66 1.63-1.57s3.83-1.39 5.47-1.4c1.63 0 7.46-2.29 8.23-2.37.77-.07 2.77 2.93 2.77 2.93s-6.42.87-8.93 1.42c-2.51.56-5.82 2.59-6.8 2.85-1.67.43-2.49.75-2.49.75s-.11-.92 2.47-1.76c.68-.22.07-.86.07-.86l-2.4.67z"
          />
          <path
            className="heroImgTwo116"
            d="M509.83 523.74s-2 .6-2.64 1.42c-.45.58-2.11 3.62-2.11 3.62s1.2-.47 1.88-1.69c.97-1.79 2.87-3.35 2.87-3.35z"
          />
          <path
            className="heroImgTwo119"
            d="M509.97 524.8s.44.4.39.66c.17-.1.3-.25.42-.35.27-.23 2.31-1.38 2.31-1.38s-1.63.55-2.14.61c-.32.04-.99.29-1.55.5-.07.03-.16.12-.27.19l.84-.23z"
          />
          <path
            className="heroImgTwo119"
            d="M509.9 525.67c-1.73.56-2.25 1.16-2.41 1.5.41-.14 1.07-.34 2.01-.59.34-.09.96-.39 1.73-.77.15-.07-.07-.67.96-1.66.18-.18-1.34.29-2.16.75.16.18.4.6-.13.77z"
          />
        </g>
        <g>
          <path
            className="heroImgTwo129"
            d="M522.32 527.91c0-.01.01-.02.02-.03l-.02-.89h-37.03v.92l.09.17c.48.77 1.33.91 2.31.91h32.23c.98 0 1.83-.14 2.31-.91l.09-.17z"
          />
          <path
            className="heroImgTwo126"
            d="M513.39 528.06s-.01.02 0 .03l.09.13c.15.2.33.35.54.46h5.63c.94 0 1.76-.11 2.22-.74l.09-.14c0-.01.01-.01.02-.02l-.01-.44h-8.58v.72z"
          />
          <path
            className="heroImgTwo122"
            d="M489.89 527.19H508.4V527.82H489.89z"
          />
          <path
            className="heroImgTwo114"
            d="M513.19 526.95l-4.8-18.2c-.13-.48-.62-.87-1.1-.87h-25.98c-.48 0-.77.39-.64.87l4.8 18.2h27.72z"
          />
          <path
            className="heroImgTwo129"
            d="M498.5 517.42c.25.93-.31 1.68-1.24 1.68-.93 0-1.88-.75-2.13-1.68-.24-.93.31-1.68 1.24-1.68.93 0 1.88.75 2.13 1.68z"
          />
        </g>
      </g>
      <g>
        <path
          className="heroImgTwo144"
          d="M280.21 594.41c-4.67 1.17-13.26 5.36-15.07 12.51-2.7 10.74 2.7 26.5-5.85 41.92-2.73 4.91 47.29 11.52 47.29 11.52l9.65-54.26s-12.05-11.78-14.52-12.57c-5.11-1.64-12.18-1.46-21.5.88z"
        />
        <path
          className="heroImgTwo145"
          d="M316.31 606.14c1.74 4.33 6.13 30.13 1.23 36.21-.56.7 2.54 1.79 1.98 5-.1.54-1.03 3.09-1.03 3.09l-4 1.04-12.08-6.88 2.31-2.81 1.09.02s1.04-.96.75-2.15c-.29-1.18 2.77-3.1 7.39-23.12 1.37-5.88 1.98-11.36 2.36-10.4z"
        />
        <path
          d="M311.64 641.12l-3.57-.79-1.45-.23c-.01.98-.8 1.71-.8 1.71l2.77.08 3.24.7 3.93 2.23-4.12-3.7z"
          fill="#ecb60d"
        />
        <path
          d="M306.53 644.46s2.76-.04 5.12 1.41c1.79 1.1 3.44 3.5 1.79 4.8-1.65 1.3-4.18 2.51-4.18 2.51s-6.3.18-2.73-8.72z"
          fill="#c4d1e1"
        />
        <path
          className="heroImgTwo164"
          d="M313.43 650.77l-1.68-.36s-5.8-.53-6.62-.08c-.83.46-3.48 2.25-3.48 2.25l-.55.5s1.29.8 3.04-.41c1.75-1.22 1.9-.28 1.9-.28l-.3 1.54.03 1.97-.27.83s-.25.45-.05.62c.55.48 1.1.25 1.1.25l1.76-3.08.48 3.35s.24.45.79.57c.52.11.52-.03.52-.03l.4-.85c.04.17.14.41.35.58.44.35 1.05.3 1.05.3l-.16-3.52.72 1.79s.26.44.79.57c.58.14.74.18.74.18l-.56-6.69z"
        />
        <path
          className="heroImgTwo116"
          d="M283.42 636.97s4.2 10.66 14.31 13.51c1.75.49 2.82 2.38 1.98 2.99-1.08.78-2.56.95-5.1.94-5.29-.01-12.48-1.65-17.45-6.47-7.37-7.12-9.68-10.36-8.94-13.95.74-3.59 15.2 2.98 15.2 2.98z"
        />
        <path
          className="heroImgTwo145"
          d="M266.58 603.33c-2.14 3.52-3.38 9.17-3.36 17.25.02 6.26 5.41 16.5 6.24 19.65.14.52.47 2.78.47 2.78l5.98 5.87 12-8.13-3.65-5.6-1.09.12s-.69-3.09-.51-4.29c.19-1.2-3.32-17.34-5.06-27.68-.99-5.97-8.9-3.46-11.02.03z"
        />
        <path
          className="heroImgTwo144"
          d="M277.32 635.06l3.5-1.08 1.42-.35c.09.98.93 1.64.93 1.64l-2.75.32-3.18.97-3.72 2.56 3.8-4.06z"
        />
        <path
          className="heroImgTwo116"
          d="M296.96 650.44l6.11-1.67s1.73 2.32 1.4 2.9c-.33.58 0 4.01 0 4.01l-2.96 2.52-2.41-.8s-.17-1.05.65-1.15c.83-.1-.55-.7-.99-.95-.42-.25-3.96-2.07-1.8-4.86z"
        />
        <path
          className="heroImgTwo164"
          d="M287.6 593.89c-.68 1.94.35 4.08 2.29 4.76l3.47 1.22c1.94.68 4.08-.35 4.76-2.29l3.09-8.81c.68-1.94-.35-4.08-2.29-4.76l-3.47-1.22c-1.94-.68-4.08.35-4.76 2.29l-3.09 8.81z"
        />
        <g>
          <path
            className="heroImgTwo116"
            d="M302.73 601.64a2.402 2.402 0 01-2.96 1.68l-10.46-2.88a2.4 2.4 0 01-1.68-2.97l4.27-15.5a2.402 2.402 0 012.96-1.68l10.46 2.88c1.28.35 2.04 1.68 1.69 2.97l-4.28 15.5z"
          />
          <path
            className="heroImgTwo117"
            d="M306.13 589.34s1.82-1.11 2.33-1.97c.26-.43.29-2.31-.68-3.32-.98-1-2.29-.01-2.29-.01s1.57 1.55 1.56 1.96c-.01.42-.92 3.34-.92 3.34z"
          />
          <path
            className="heroImgTwo142"
            d="M288.9 588.65s.06-6.39.83-8.05c.76-1.67 1.29-2.83 3.48-2.43 3.27.59 3.12 1.28 4.94.74 1.82-.54 5.25-2.11 6.27-2.08 1.7.05 4.06 1.28 3.33 4.3-.54 2.24.71 3.17.65 3.24-.2.24-.85.33-2 .56-3.31.66-5.14.46-7.2-.73-2.07-1.2-5.87-3.6-6.81-2.09-.94 1.51-2.13 6.61-2.44 6.94-.3.33-.74 0-1.05-.4z"
          />
          <path
            className="heroImgTwo116"
            d="M290.91 594.21c-.12.43-.56.68-.99.56l-2.26-.62a.801.801 0 01-.56-.99l1.14-4.13c.12-.43.56-.68.99-.56l2.26.62c.43.12.68.56.56.99l-1.14 4.13zM304.4 597.92c-.12.43-.57.69-.99.57l-2.26-.62a.81.81 0 01-.56-.99l1.14-4.13c.12-.43.56-.68.99-.56l2.26.62c.43.12.68.56.56.99l-1.14 4.12z"
          />
          <path
            className="heroImgTwo142"
            d="M289.53 590.29s-1.68 6.1-2.24 8.14c-.5 1.84-.88 6.08 9.51 8.94 6.15 1.69 7.17-2.88 7.58-4.37.31-1.13-.03-4.87-.51-5.7-.02-.03-.69 2.52-.69 2.52s-.6-2.8-3.53-3.61c-4.72-1.29-6.55 1.17-8.61.55-1.6-.49-2.08-3.44-1.51-6.47z"
          />
          <path
            className="heroImgTwo116"
            d="M296.6 599.67l3.23.89c.42.12.87-.13.99-.56l.12-.45-4.78-1.32-.13.45c-.11.43.14.87.57.99z"
          />
        </g>
        <path
          d="M347.64 661.57c-1.49 4.12-27.59 19.28-36.34 18.38l-26.36 1.26c-22.42-2.74-33.03-37.57-33.03-37.57.89-8.75 22.98 3.93 30.85 7.86 12.47 6.22 20.35 6.54 20.35 6.54 8.34.86 18.01-5.58 28.54-5.8 11.56-.26 18.34 2.84 15.99 9.33z"
          fill="#282723"
        />
        <g>
          <path
            className="heroImgTwo166"
            d="M290.29 657.39c-.01-.01-.01-.02-.02-.04v-1.1l45.38-.83.02 1.1s.01.02 0 .04l-.11.21c-.57.96-1.61 1.15-2.81 1.18l-39.49.72c-1.2.02-2.24-.13-2.85-1.07l-.12-.21z"
          />
          <path
            className="heroImgTwo152"
            d="M301.22 657.38s.01.02 0 .03l-.11.17c-.18.25-.4.43-.65.57l-6.9.12c-1.15.02-2.16-.1-2.74-.86l-.11-.17c-.01-.01-.02-.01-.02-.03v-.54l10.52-.19.01.9z"
          />
          <path
            transform="rotate(-1.049 318.73 656.496)"
            className="heroImgTwo167"
            d="M307.33 655.99H330.01V656.76H307.33z"
          />
          <path
            className="heroImgTwo120"
            d="M301.45 656.02l5.48-22.41c.15-.59.74-1.08 1.33-1.09l31.84-.58c.59-.01.95.45.81 1.05l-5.48 22.41-33.98.62z"
          />
        </g>
        <g>
          <path
            className="heroImgTwo116"
            d="M280.91 687.01c3.14.54 5.53-3.11 5.53-3.11l8.12-6.39-1.72-4.32-15.8 7.1s.73 6.18 3.87 6.72z"
          />
          <path
            className="heroImgTwo145"
            d="M286.45 683.66s.01.38-.6 2.04c-.61 1.67-1.35 2.25-2.13 2.48-3.03.89-5.21.44-12.5 1.84-7.29 1.4-9.47.35-11.6-3.01-2.14-3.36.08-6 3.63-6.27 5.14-.38 8.01.2 10.69-.21 2.8-.43 2.98-1.05 4.62-2.07 1.54-.95 2.96 4.69 1.5 6-1.27 1.16 5.3 1.33 6.39-.8z"
          />
          <path
            className="heroImgTwo1"
            d="M284.89 686.9c-3.03.89-5.21.44-12.5 1.85-7.28 1.4-9.47.34-11.6-3.01-.99-1.55-1.03-2.94-.46-4.02-1.6 1.1-2.15 3.03-.71 5.3 2.13 3.36 4.31 4.41 11.6 3.01 7.29-1.4 9.47-.95 12.5-1.84.61-.18 1.18-.58 1.69-1.5-.16.08-.34.15-.52.21z"
          />
          <path
            className="heroImgTwo116"
            d="M286.44 683.75c.01-.07.01-.1.01-.1-.02.04-.05.07-.07.11.02 0 .04 0 .06-.01z"
          />
          <path
            className="heroImgTwo1"
            d="M270.7 686.28c-.23.09-.44-.35-.51-.97-.14-1.26-.42-2.51-.84-3.75-.21-.62-.2-1.16.05-1.23.26-.07.67.37.87.97.4 1.22.67 2.47.8 3.71.06.61-.13 1.18-.37 1.27zM272.97 685.87c-.25.05-.47-.38-.54-.96a16.13 16.13 0 00-.83-3.53c-.2-.58-.19-1.08.08-1.11.27-.03.69.4.89.98.4 1.16.66 2.34.79 3.49.04.57-.15 1.07-.39 1.13zM275.33 685.76c-.26.03-.52-.42-.61-1.03-.19-1.21-.52-2.43-.99-3.64-.23-.6-.23-1.1.06-1.11.28-.01.72.45.95 1.05.45 1.21.76 2.41.94 3.6.09.6-.09 1.1-.35 1.13z"
          />
        </g>
        <path
          d="M281.46 676.58s55.77-22.92 58.95-22.96c10.77-.1 8.3 10.8 2.85 15.93-6.44 6.05-55.4 17.12-55.4 17.12l-6.4-10.09z"
          fill="#323b31"
        />
        <g>
          <path
            className="heroImgTwo116"
            d="M303.58 680.56L288.36 666.13 294.85 659.71 311.47 677.17z"
          />
          <path
            d="M306.74 669.22s-38.74-35.51-41.35-36.57c-8.84-3.59-14.32 6.18-14.01 9.55.4 4.37 39.76 39.94 39.76 39.94l15.6-12.92z"
            fill="#3c463b"
          />
          <path
            className="heroImgTwo145"
            d="M329.69 681.69c.91.84 1.06 3.3 1.06 3.3s-12.93 4.89-17.47 4.97c-1.18.03-3.3-.45-5.33-1.01-.34-.09-1.13-.98-1.46-1.08-.62-.18-.76.42-1.29.25-1.38-.44-2.36-.81-2.39-.88-2.73-5.8-1.81-8.67-1.81-8.67s5.94 1.7 4.92.37c-2.18-2.84 4.04-5.75 5.43-4.23 5.53 6.06 15.72 4.56 18.34 6.98z"
          />
          <path
            className="heroImgTwo1"
            d="M330.41 682.9c-3.97 1.58-12.32 4.84-13.99 4.96-6.8.48-11.56-1.94-14.74-3.36.28.83.64 1.74 1.11 2.75.03.07 1.02.44 2.39.88.53.17.67-.43 1.29-.25.33.1 1.12.99 1.46 1.08 2.03.56 4.16 1.04 5.33 1.01 4.54-.09 17.47-4.97 17.47-4.97s-.01-1.13-.32-2.1zM321.27 679.13c.12.22-.38.49-1.11.64-1.47.3-2.94.74-4.37 1.32-.71.29-1.35.36-1.45.11-.09-.25.39-.72 1.09-1 1.42-.56 2.87-.99 4.32-1.27.72-.14 1.4-.02 1.52.2zM318.37 678.11c.11.23-.39.55-1.09.75-1.43.42-2.84.97-4.22 1.66-.68.34-1.3.45-1.39.19-.09-.26.38-.78 1.05-1.1a25.16 25.16 0 014.18-1.61c.7-.19 1.36-.12 1.47.11zM315.58 677.21c.1.24-.38.61-1.07.87-1.39.53-2.75 1.19-4.07 1.98-.65.39-1.25.53-1.33.26-.09-.27.36-.83 1.01-1.22 1.31-.78 2.67-1.42 4.04-1.92.68-.25 1.32-.21 1.42.03z"
          />
        </g>
      </g>
      <g>
        <path
          className="heroImgTwo120"
          d="M570.69 573.95L567.96 573.95 631.3 337.55 634.03 337.55z"
        />
        <path
          className="heroImgTwo120"
          d="M607.95 573.95L605.22 573.95 668.57 337.55 671.29 337.55z"
        />
        <path
          className="heroImgTwo120"
          d="M628.74 352.2L629.47 349.48 667.64 349.48 666.91 352.2z"
        />
        <path
          className="heroImgTwo120"
          d="M623.17 372.99L623.9 370.27 662.07 370.27 661.34 372.99z"
        />
        <path
          className="heroImgTwo120"
          d="M617.6 393.78L618.33 391.06 656.5 391.06 655.77 393.78z"
        />
        <path
          className="heroImgTwo120"
          d="M612.03 414.57L612.76 411.84 650.93 411.84 650.2 414.57z"
        />
        <path
          className="heroImgTwo120"
          d="M606.46 435.36L607.19 432.63 645.36 432.63 644.63 435.36z"
        />
        <path
          className="heroImgTwo120"
          d="M600.89 456.15L601.62 453.42 639.79 453.42 639.06 456.15z"
        />
        <path
          className="heroImgTwo120"
          d="M595.32 476.94L596.05 474.21 634.22 474.21 633.49 476.94z"
        />
        <path
          className="heroImgTwo120"
          d="M589.75 497.73L590.48 495 628.65 495 627.92 497.73z"
        />
        <path
          className="heroImgTwo120"
          d="M584.18 518.52L584.91 515.79 623.08 515.79 622.35 518.52z"
        />
        <path
          className="heroImgTwo120"
          d="M578.61 539.31L579.34 536.58 617.51 536.58 616.78 539.31z"
        />
        <path
          className="heroImgTwo120"
          d="M573.03 560.09L573.77 557.37 611.94 557.37 611.21 560.09z"
        />
      </g>
      <g>
        <path
          className="heroImgTwo118"
          d="M607.23 470.92c.44-.41.3-1.65.3-1.65s-6.56-1.53-8.75-1.28c-.57.07-1.56.43-2.51.84-.16.07-.48.54-.64.61-.29.13-.39-.16-.64-.04-.63.31-1.09.55-1.1.58-.94 2.98-.31 4.3-.31 4.3s5.27 2.35 6.65 1.36c3.51-2.49 6.05-3.81 7-4.72zM622.8 465.94l-.65 5.03 4.83 1.59s.49-.58.9-.45c.34.12.57.93 1.04 1.09 1.02.33 2.14.7 2.59.85l9.69 3.19s.54-.93.39-1.36c-.07-.22-.78-.79-1.27-1.12-2.26-1.52-8.68-6.06-9.13-8.85-.57-3.39-8.39.03-8.39.03z"
        />
        <path
          className="heroImgTwo116"
          d="M632.72 303.86l-.56-6.14s4.21-.91 4.35-3.36c.29-4.92-3.17-11.61-4.74-13.31-4.46-4.83-8.58-14.39-19.2-2.65-4.04 4.47 7.9 16.74 9.41 19.71 1.5 2.98.9 7.1.9 7.1l3.71 1.37 6.13-2.72z"
        />
        <path
          className="heroImgTwo119"
          d="M604.78 344.11l25.12 8.31s-15.15 2.78-27.61-.38c-12.45-3.16 1.26-7.84 2.49-7.93z"
        />
        <path
          className="heroImgTwo117"
          d="M613.16 406.63s9.69 13.11 5.58 23.8c-7.33 19.01-13.22 34.25-15.66 40.61-.95 2.47-1.62 1.92-1.62 1.92l-5.59-.04-2.36-2.59 13.18-43.92 6.47-19.78z"
        />
        <path
          className="heroImgTwo116"
          d="M671.72 303.64l16.36-18.39s-6.98 25.88-15.54 27.67c-8.56 1.8-.82-9.28-.82-9.28z"
        />
        <path
          className="heroImgTwo122"
          d="M622.18 304.66s-5.56-.85-16.95 19.3c-11.39 20.15-9.1 20.1-9.1 20.1l-1.33 5.08 7.7 3.99 11.51-16.75 8.17-31.72z"
        />
        <path
          className="heroImgTwo117"
          d="M640.93 371.33s-11.98 42.38-26.79 66.23c-7.93 12.77-6.75-5.96-6.75-5.96l14.38-76.52 26.59-.26c0-.01-2.9 3.39-7.43 16.51z"
        />
        <path
          className="heroImgTwo117"
          d="M623.28 368.56s19.72 43.85 20.63 46.96c4.44 15.19 11.48-3 11.93-6.65.45-3.65-.25-22.49-4.6-40.74l-4.35-18.25-23.61 18.68z"
        />
        <path
          className="heroImgTwo117"
          d="M643.69 407.12s-3.93 7.27-8.33 16.77c-3.23 6.97-10.07 27.68-13.47 42.51-.21.91 9.82 1.23 9.82 1.23s16.59-39.17 20.32-47.94c3.75-8.75-.48-17.13-8.34-12.57z"
        />
        <path
          className="heroImgTwo122"
          d="M614 310.62l6.75-6.64 2.88-3.58 12.02-1.22 2.4 3.77 9.68 3.88s2.61 3.93 1.73 19.29c-.96 17.02 6.65 40.67 3.16 43.1-22.88 15.87-34.17 2.36-34.17 2.36s-10.18-32.52-4.45-60.96z"
        />
        <path
          className="heroImgTwo122"
          d="M631.16 308.54s-1.45 2.81 6.34 5.34c13.27 4.32 27.38 3.49 30.15 3.41 2.76-.08 6.62-.3 9.41-5.19 2.13-3.73 2.87-4.96 2.87-4.96l-7.36-5.61-1.76 1.99-.77 1.24s-10.14-.94-19.65-1.91c-22.21-2.24-19.23 5.69-19.23 5.69z"
        />
        <path
          className="heroImgTwo142"
          d="M628.6 283.8s-1.74-.46-1.62.73c.11 1.2 1.64 3.44 2.38 3.47.74.02 1.87 3 1.87 3l-.79 3.92-9.12 2.72s-3.19-.4-4.02-2.36c-.83-1.96-7.34-15.6-7.34-15.6l5.41-5.29s2.88-2.7 5.04-4.08c3.32-2.12 4.66-.41 4.85.64.19 1.05 1.35.83 2.82.39 1.47-.44 3.61.15 3.02 1.07-.25.38 1.48.83 1.22 2.08-.37 1.78-2.99 3.11-2.48 5.65.23 1.13.87 4.99-1.24 3.66z"
        />
        <path
          className="heroImgTwo116"
          d="M686.9 290.42s-3.71-1.92-3.94-3.09c-.23-1.17-.78-5.52-.78-5.52l-.04-1.12s1.21-.74 1.55 2.18c.34 2.93.95 1.4.95 1.4l.7-2.78 1.13-3.34.31-1.54s.11-.88.31-1.08c.59-.55.79.11.79.11l-.7 6.1 2.18-5.47s.4-.66.79-.59c.37.06.29.29.29.29l-1.01 6.74 1.44-2.69s.4-.63.79-.59c.42.05.54.05.54.05l-4.11 11.14-1.19-.2z"
        />
      </g>
      <g>
        <path
          className="heroImgTwo117"
          d="M283.42 930.86c33.87-1.06 17.79-17.12 41.28-28.58 9.12-4.45 16.25 4.49 19.76 8.05l3.57 1.48c3.69 3.75 1.74 10-2.01 13.69l-5.77 5.68c-6.68 5.33-3.45 14.33-7.76 18.57-4.83 4.75-12.62 4.65-17.54-.16-.15.16-.29.32-.44.48-4.88 4.81-28.01 8.17-33.46 3.79-10.4-8.34-3.56-22.82 2.37-23z"
        />
        <path
          className="heroImgTwo119"
          d="M329.08 968.69s.26 2.12 10.53 1.14c10.27-.97 25.1-4.95 25.1-4.95l-23.91.04c.01-.01-9.13.44-11.72 3.77z"
        />
        <path
          className="heroImgTwo119"
          d="M356.71 966.4c4.89-.32 7.15 2 9.14 2.64 1.99.63 4.02 1.67 4 1.65-1.22-2.02-2.81-1.98-3.65-2.6-.84-.63-.61-.3 1.13-.54.99-.14 2.85 1.5 4.15 2.38 1.15.77 1.83.79 1.83.79s-1.38-1.69-3.09-3.01c-2.06-1.59-4.56-2.88-5.15-3.06-1.07-.34-4.35-.24-6.66.22-2.61.52-5.23 1.76-1.7 1.53z"
        />
        <path
          className="heroImgTwo119"
          d="M364.82 964.77s3.81.71 4.5 1.16c.48.31 2.52 2.21 2.52 2.21s-1.42-.14-2.28-.85c-.85-.7-4.74-2.52-4.74-2.52z"
        />
        <path
          className="heroImgTwo158"
          d="M333.31 963.94s-9.44 1.18-8.45 6.76c.57 3.17 2.79 4.15 5.65 3.74 5.29-.76 11.28-1.9 11.28-1.9l2.16-8.9-10.64.3z"
        />
        <path
          className="heroImgTwo158"
          d="M333.06 967.92l-4.3-30.94s-.39-6.63-2.76-6.29c-3.42.49-8.7 10.55-8.83 12.63-.25 4.24 5.88 26.03 6.67 30.37.54 2.99 3.19-.62 6.23-1.17 5.67-1.01 2.99-4.6 2.99-4.6z"
        />
        <path
          className="heroImgTwo116"
          d="M205.12 929.17L195.91 913.03 185.14 913.71 179.81 915.29 191.73 917.06 199.35 932.12z"
        />
        <path
          className="heroImgTwo142"
          d="M194.5 910.47l-1.4.46-.65 1.81-8.7.37-6.87 1.89s.18.52.52.67c1.3.55 6.74.41 6.74.41s-.7-1.46-.11-1.83c.59-.37 7.32-.54 8.95-.45 2.8.15 4.29.72 4.14.36-.24-.5-2.62-3.69-2.62-3.69z"
        />
        <path
          className="heroImgTwo117"
          d="M251 959.64l-25.29 2.87-22.45-39.71-7.55 3.76 22.72 48.15s1.73 3.02 9.39 2.36c7.45-.64 37.59-2.42 40.93-3.67 7.9-2.95-18.55-6.77-17.75-13.76z"
        />
        <path
          className="heroImgTwo116"
          d="M347.56 918.64c-.63.61-1.33 1.18-2.08 1.72-5.03 3.6-10.95 4.21-14.21 1.71-2.06 1.86-4.23 3.21-6.2 4.21-2.25 1.15-6.05.93-6.05.93-3.53 2.51-5.6 12.58 2.45 16.53 4.83 2.38 5.68-3.3 5.1-7.24-.28-1.85-.44-3.92.05-4.73 1.72-2.83 2.98-2.86 5.15-3.26 3.5-.26 5.78 2.12 5.78 2.12.23.22.52.33.83.33.58-.3 1.13-.69 1.62-1.18l5.6-5.51a6.765 6.765 0 001.96-5.63z"
        />
        <path
          className="heroImgTwo116"
          d="M225.36 920.64L225.05 907.75 222.44 904.65 215.49 900.56 210.29 901.46 219.38 908.34 219.95 921.86z"
        />
        <path
          className="heroImgTwo142"
          d="M224.67 904.93l-2.34-1.7-.73.56-6.45-4.16s-5.89-1.59-7.04-.25c-.37.43.58 1.16 1.88 2.14 1.04.78 1.68 1.09 1.68 1.09s.27-1.01 2.61-1.37c.74-.12 5.34 2.98 6.93 3.78 2.84 1.45 3.96 3.46 4.1 3.27.38-.49-.64-3.36-.64-3.36zM276.43 980.52l-5.71-.24-46.38-3.3s-4.16 1.25-6.76-5.33c-3.69-9.36 1.57-56.07 1.57-56.07l6.87.16 3.38 44.1s32.55-10.4 39.94-7.83c8.63 3 13.59 7.09 24.47 18.4 4.79 4.98-1.56 9.29-1.56 9.29l-15.82.82z"
        />
        <path
          className="heroImgTwo123"
          d="M328.66 933.13s-7.84-9.8-14.37-3.92c-6.53 5.88-29.36 24.21-36.28 26.33l-1.58 25.72c18.86 1.25 34.33-8.39 35.61-10.31 4.15-6.23 19.23-29.99 16.62-37.82z"
        />
        <path
          className="heroImgTwo158"
          d="M305.95 964.85c1.84-2.52 5.64-7.92 9.32-13.97 4.65-7.64 11.74-6.37 7.94 1.13-4.02 7.91-9.03 15.74-11.17 18.94-1.28 1.93-13.81 11.56-32.66 10.31l1.84-2.61c13.49-1.28 23.54-12.17 24.73-13.8z"
        />
        <g>
          <path
            className="heroImgTwo116"
            d="M317.48 975.05s-2.84 3.04 9.99 1.75c9.11-.93 8.9-.29 31.37-6.09l-29.85.48s-8.25.02-11.51 3.86z"
          />
          <path
            className="heroImgTwo116"
            d="M348.84 972.58c6.12-.45 8.93 2.15 11.41 2.84 2.48.69 5.01 1.83 4.99 1.81-1.52-2.28-3.5-2.2-4.54-2.9-1.04-.7-.76-.33 1.41-.64 1.23-.17 3.54 1.66 5.19 2.64 1.42.85 2.27.87 2.27.87s-1.71-1.89-3.84-3.36c-2.57-1.77-5.69-3.19-6.42-3.39-1.34-.37-5.43-.2-8.33.37-3.27.63-6.55 2.09-2.14 1.76z"
          />
          <path
            className="heroImgTwo119"
            d="M358.99 970.58s4.75.74 5.61 1.24c.6.35 3.14 2.46 3.14 2.46s-1.77-.13-2.84-.92c-1.07-.8-5.91-2.78-5.91-2.78z"
          />
          <path
            className="heroImgTwo123"
            d="M324.17 969.96l4.24-34.97s-2.73-3.45-5.14-2.25c-2.57 1.28-7.7 4.82-7.7 4.82s-4.3 12.14-5.46 22.79c-1.92 17.66-.99 18.05 5.46 19.58 6.74 1.61 18.96-.03 18.96-.03l2.71-10-13.07.06z"
          />
        </g>
        <g>
          <path
            d="M345.12 978.35c-.01-.02-.01-.03-.02-.04l.02-1.35h55.92v1.35s.01.03 0 .04l-.14.25c-.72 1.17-2.01 1.38-3.48 1.38h-48.67c-1.48 0-2.77-.21-3.49-1.38l-.14-.25z"
            fill="#d1dbea"
          />
          <path
            className="heroImgTwo166"
            d="M358.59 978.58s.01.02 0 .04l-.14.2c-.22.3-.5.53-.82.69h-8.5c-1.42 0-2.66-.17-3.35-1.12l-.13-.21-.03-.03.01-.67h12.96v1.1z"
          />
          <path
            className="heroImgTwo166"
            d="M366.14 977.27H394.09V978.22H366.14z"
          />
          <path
            className="heroImgTwo170"
            d="M358.91 976.91l7.25-27.48c.19-.73.93-1.31 1.66-1.31h39.24c.72 0 1.16.59.96 1.31l-7.25 27.48h-41.86z"
          />
          <path
            className="heroImgTwo166"
            d="M381.09 962.51c-.37 1.4.47 2.54 1.87 2.54 1.4 0 2.84-1.14 3.21-2.54.37-1.4-.47-2.54-1.87-2.54-1.4 0-2.84 1.14-3.21 2.54z"
          />
        </g>
      </g>
      <g>
        <path
          className="heroImgTwo142"
          d="M239.64 311.52c4.35-3.66 22.99-5.1 27.67-14.05 2.61-4.99 7.47 8.12 5.95 13.23 8.91 27.84 4.49 37.07-3.37 36.49-41.71-3.07-41.18-26.48-30.25-35.67z"
        />
        <path
          className="heroImgTwo119"
          d="M276.96 356.21s-.42 1.85 8.51 4.12c8.92 2.27 22.59 3.38 22.59 3.38l-20.11-7.15s-7.81-2.37-10.99-.35z"
        />
        <path
          className="heroImgTwo119"
          d="M300.87 362.59c4.21 1.21 5.41 3.84 6.89 4.97 1.48 1.13 2.87 2.61 2.87 2.59-.42-2.07-1.76-2.51-2.28-3.29-.51-.78-.42-.43 1.11-.12.87.18 1.93 2.11 2.78 3.25.73.99 1.3 1.22 1.3 1.22s-.66-1.83-1.7-3.46c-1.26-1.95-2.98-3.78-3.41-4.12-.8-.61-3.58-1.51-5.67-1.83-2.35-.34-4.93-.08-1.89.79z"
        />
        <path
          className="heroImgTwo119"
          d="M308.18 363.65s2.99 1.74 3.43 2.33c.31.41 1.46 2.61 1.46 2.61s-1.15-.54-1.66-1.39c-.5-.85-3.23-3.55-3.23-3.55z"
        />
        <path
          className="heroImgTwo0"
          d="M281.94 353.49s-8.29-1.85-9.13 3.14c-.48 2.84 1.11 4.33 3.62 4.84 4.68.96 10.06 1.8 10.06 1.8l4.49-6.84-9.04-2.94z"
        />
        <path
          className="heroImgTwo0"
          d="M282.41 349.37l-2.31-22.67s.17-5.93-1.97-5.81c-3.08.17-3.67 5.29-3.94 7.13-.54 3.74-1.33 5.22-1.27 14.65.04 5.82-.38 12.38-.01 16.3.25 2.7 2.88-.3 5.63-.55 5.11-.48 3.87-9.05 3.87-9.05z"
        />
        <path
          className="heroImgTwo116"
          d="M332.16 412.87L339.73 429.29 350.23 429.52 355.54 428.45 344.12 425.73 338 410.49z"
        />
        <path
          className="heroImgTwo142"
          d="M340.89 431.88l1.4-.33.78-1.7 8.47.38 6.82-1.27s-.13-.51-.46-.69c-1.21-.65-6.5-.96-6.5-.96s.56 1.47-.04 1.78c-.6.31-7.14-.1-8.71-.31-2.7-.38-4.1-1.07-3.97-.71.16.53 2.21 3.81 2.21 3.81z"
        />
        <path
          className="heroImgTwo158"
          d="M290.23 379.49l24.75-.66 18.45 40.37 7.64-3.02-17.99-48.58s-1.43-3.07-8.9-3.07c-7.28 0-36.65-.8-39.98.12-7.92 2.21 17.39 8.13 16.03 14.84z"
        />
        <path
          className="heroImgTwo142"
          d="M284.17 308.81c-2.06 4.22-7.15 5.98-11.37 3.92l-.14-4.57c-4.22-2.06-5.97-7.15-3.92-11.36l1.4-2.87c2.05-4.22 7.14-5.98 11.37-3.92l1.91.93c4.22 2.06 5.98 7.15 3.92 11.37l-3.17 6.5z"
        />
        <path
          className="heroImgTwo116"
          d="M286.59 296.09c-.35.7-.76 1.39-1.23 2.07-3.16 4.53-7.96 6.8-11.45 5.67-1.19 2.17-2.61 3.96-3.97 5.39-1.55 1.64-4.81 2.58-4.81 2.58-2.22 3.15-.97 12.25 6.98 13.18 4.78.56 3.8-4.47 2.13-7.61-.78-1.47-1.53-3.17-1.36-4 .6-2.9 1.66-3.29 3.36-4.28 2.87-1.26 5.5.06 5.5.06.26.13.54.13.78.03.4-.43.75-.92 1.02-1.48l3.07-6.3c.85-1.74.78-3.7-.02-5.31z"
        />
        <path
          className="heroImgTwo115"
          d="M261.65 371.49s3.63.75 4.64 1.13c1.35.5 5.29-.13 5.29-.13s.5-19.45 1.46-26.24c.95-6.79 2.71-8.63 2.7-10.75-.02-3.39-.3-4.18-.9-6.82-.6-2.63 1.15-6.13.55-5.46-.96 1.08-4.55 2.76-7.91-.96-.58-.65-5.83 49.23-5.83 49.23z"
        />
        <path
          className="heroImgTwo23"
          d="M274.53 315.33s4.94 4.97 4.74 6.61c-.2 1.63.3 8.5-.83 15.81-1.14 7.31-2.05 9.12-2.23 14.46-.19 5.34 1.05 6.53.6 13.08-.35 5.13-1.16 9.66-1.16 9.66s-2.33.65-4.08-1.26c-1.76-1.9-1.12-19.75-.47-24.14.65-4.39 3.23-15.91 3.07-18.97-.16-3.06-1.14-10.37-1.69-11.33-1.35-2.42 1.66-3.67 2.05-3.92z"
        />
        <path
          className="heroImgTwo0"
          d="M274.55 314.86s-.27.43-.61.66c-.64.45-1.45 1.32-1.45 1.32s.99-.48 1.36.38c.46 1.05.75 3.41.75 3.41s1.83-3.01 1.39-3.98c-.78-1.72-1.44-1.79-1.44-1.79z"
        />
        <path
          className="heroImgTwo116"
          d="M307.74 416.45L308.11 427.95 310.45 430.71 316.69 434.3 321.32 433.47 313.16 427.39 312.57 415.32z"
        />
        <path
          className="heroImgTwo142"
          d="M308.46 430.47l2.1 1.5.64-.51 6.95 3.81s3.67 1.85 4.69.65c.33-.38-.08-1.64-1.26-2.52-.93-.69-1.5-.96-1.5-.96s-.24.9-2.32 1.24c-.67.12-4.79-2.62-6.21-3.33-2.55-1.27-3.56-3.06-3.69-2.89-.33.46.6 3.01.6 3.01z"
        />
        <path
          className="heroImgTwo123"
          d="M258.74 355.82l2.98 7.06 5.13.63 41.43 2.64s3.71-1.15 6.06 4.71c3.36 8.34-1.03 50.07-1.03 50.07l-6.14-.1-3.31-39.34s-39.82 6.97-51.28 3.44c-4.4-1.35-7.76-9.12-7.13-16.97.49-6.16 4.6-11.81 4.6-11.81l8.69-.33z"
        />
        <path
          className="heroImgTwo23"
          d="M264.37 370.64s1.92-17.37 3.01-25.59c1.09-8.22.85-22.01 1.1-23.99.25-1.98 1.29-4.83 1.29-4.83s-2-4.49-4.62-4.34c-2.3.13-7.25 3.49-10.76 4.91-2.43.99-3.46 27.75-3.68 29.21-.78 5.25-3.37 11.28-5.04 15.44-1.33 3.31-2.36 8.25-2.36 8.25s13.27-1.55 21.06.94z"
        />
        <path
          className="heroImgTwo0"
          d="M265.65 310.54l1.95 2.2s2.72 3.76 1.84 3.24c-1.12-.67-2.41 3.26-2.41 3.26l-3.62-7.43 2.24-1.27z"
        />
        <g>
          <path
            className="heroImgTwo116"
            d="M249.77 352.74s-3.03 2.15 8.42 3.33c11.45 1.18 28.53.33 28.53.33l-26.19-4.96s-7.22-1.47-10.76 1.3z"
          />
          <path
            className="heroImgTwo116"
            d="M277.63 356.23c5.43.71 7.42 3.49 9.46 4.54 2.05 1.04 4.04 2.5 4.03 2.48-.91-2.27-2.66-2.56-3.45-3.36-.78-.8-.6-.43 1.35-.31 1.11.07 2.8 2.08 4.06 3.24 1.09 1 1.84 1.17 1.84 1.17s-1.16-1.96-2.76-3.63c-1.93-2.01-4.4-3.81-5-4.13-1.1-.56-4.72-1.15-7.35-1.18-2.95-.01-6.09.67-2.18 1.18z"
          />
          <path
            className="heroImgTwo116"
            d="M286.86 356.31s4.02 1.5 4.68 2.1c.46.41 2.3 2.72 2.3 2.72s-1.52-.43-2.31-1.32c-.78-.88-4.67-3.5-4.67-3.5z"
          />
        </g>
        <path
          className="heroImgTwo0"
          d="M256.54 349.49l3.73-25.22s-.92-5.2-2.54-6.97c-1.91-2.11-3.33-.49-3.33-.49s-6.49 2.5-6.79 22.46c-.21 13.67-1.46 19.29 4.43 19.88 5.49.55 15.42 1.06 15.42 1.06l2.11-7.84-13.03-2.88z"
        />
        <g>
          <path
            className="heroImgTwo170"
            d="M287.63 363.14H323.77V363.88H287.63z"
          />
          <path
            className="heroImgTwo164"
            d="M303.58 363.09c0 .58.41 1.04.92 1.04h18.54c.5 0 1.19-.37 1.81-1.83l9.16-16.73c0-.73-.57-1.16-1.07-1.05l-19.02.57c-.5.12-.92.51-1.2 1.27l-9.14 16.73z"
          />
          <path
            d="M305.82 363.88h-18.28c.65.34 1.73.57 2.96.57h30.46c1.23 0 2.31-.23 2.96-.57h-18.1z"
            fill="#b7bec0"
          />
        </g>
      </g>
      <g>
        <path
          className="heroImgTwo142"
          d="M549.12 151.11s25.37.05 35.16.2c6.78.1 7.39-9.56 4.72-11.01-8.11-4.42-33.43-6.68-33.43-6.68l-17.22 15.81c0-.01.97-.03 10.77 1.68z"
        />
        <path
          className="heroImgTwo126"
          d="M573.04 99.33s6.52-1.83 9.36 6.69c4.36 13.07 3.97 25.06 3.37 27.62-.28 1.18-1.12 5.24-8.41 5.4-2.95.07-1.77-6.27-1.77-6.27s-1.9-5.38-3.91-14.48c-2.03-9.1 1.36-18.96 1.36-18.96z"
        />
        <path
          className="heroImgTwo114"
          d="M578.2 100.07l-16.73-5.41-12.67-.86-13.87 4.2s-4.54 3.13-.32 13.19c4.22 10.06 2.6 25.82 2.6 25.82l35.79 1.15s.68-14.57 3.05-29.14c.52-3.26.59-7.08 2.15-8.95z"
        />
        <path
          className="heroImgTwo126"
          d="M553.64 137.45h1.83c1.91-14.07 1.82-28.58-.29-42.6-.55.33-1.1.65-1.65.98.47 14.34-.32 28.68.11 41.62z"
        />
        <path
          className="heroImgTwo119"
          d="M560.76 98.6c-.25 1.94-1.83 3.62-3.49 3.7h-4.73c-1.66-.08-3.24-1.76-3.49-3.7-.39-3.19-.77-6.38-1.16-9.57-.26-1.91.88-5.75 3.39-6.89a9.029 9.029 0 017.24 0c2.52 1.13 3.65 4.97 3.39 6.89-.38 3.19-.77 6.38-1.15 9.57z"
        />
        <path
          className="heroImgTwo126"
          d="M554.72 102.53L551.71 107.45 546.21 100.57 547.08 96.95 548.86 95.49 548.88 98.21z"
        />
        <path
          className="heroImgTwo126"
          d="M555.08 102.53L558.08 107.45 563.2 101.04 562.36 97.11 561.08 95.46 560.56 98.68z"
        />
        <path
          className="heroImgTwo117"
          d="M554.19 76.07c-1.33 0-2.49.68-3.17 1.71h-1.3c-1.62 0-2.93 1.31-2.93 2.93v7.24h2.06V85c0-1.14.93-2.06 2.06-2.06h1.2-1.91 1.26a3.78 3.78 0 003.78-3.79v-3.09h-1.05z"
        />
        <path
          className="heroImgTwo119"
          d="M550.53 90.65c0 1-.8 1.8-1.8 1.8h-.15c-.99 0-1.79-.8-1.79-1.8v-2.18c0-.99.81-1.79 1.79-1.79h.15c.73 0 1.37.44 1.65 1.07l.15 2.9z"
        />
        <path
          className="heroImgTwo117"
          d="M552.58 78.95v-2.88h6.21c2.09 0 3.78 1.69 3.78 3.78v2.88h-6.21c-2.09 0-3.78-1.69-3.78-3.78z"
        />
        <path
          className="heroImgTwo116"
          d="M562.05 93.41c-.01 1.5-1.16 4.05-3.06 4.86-2.59 1.07-5.65 1.07-8.24 0-1.91-.8-3.05-3.35-3.06-4.86V80.6c-.01-1.51 1.5-2.78 3.36-2.82h7.64c1.86.03 3.38 1.31 3.37 2.82-.01 4.26-.01 8.53-.01 12.81z"
        />
        <path
          className="heroImgTwo117"
          d="M556.33 76.07c1.33 0 2.49.68 3.17 1.71h1.3c1.62 0 2.93 1.31 2.93 2.93v7.24h-2.06V85c0-1.14-.93-2.06-2.07-2.06h-.02.73-3.34a3.78 3.78 0 01-3.78-3.79v-3.09h3.14z"
        />
        <path
          className="heroImgTwo116"
          d="M560.02 88.47c0-.99.8-1.79 1.79-1.79h.15c.99 0 1.79.8 1.79 1.79v2.18c0 .99-.81 1.8-1.79 1.8h-.15c-.73 0-1.36-.44-1.64-1.07l-.15-2.91z"
        />
        <path
          className="heroImgTwo117"
          d="M553.18 79.69c.05-.24.07-.49.07-.74v-2.88H550.57c-2.09 0-3.79 1.69-3.79 3.78v2.88H549.46c.27 0 .53-.03.78-.08.3-1.48 1.46-2.65 2.94-2.96z"
        />
        <path
          className="heroImgTwo118"
          d="M519.96 199.62c-.39.47-.1 1.72-.1 1.72s6.83.76 9.03.25c.57-.13 1.54-.63 2.45-1.15.15-.09.42-.61.57-.7.28-.17.42.11.65-.04.61-.38 1.04-.68 1.04-.72.6-3.13-.2-4.39-.2-4.39s-5.81-1.93-6.9-.59c-2.57 3.17-5.68 4.58-6.54 5.62zM583.79 196.21l.47 5.16 5.17.5s.36-.69.79-.65c.37.04.77.8 1.27.85 1.09.11 2.29.22 2.78.28.97.09 10.36 1.01 10.36 1.01s.34-1.04.09-1.44c-.12-.2-.96-.62-1.52-.83-2.59-1.01-10-4.1-11.08-6.78-1.3-3.26-8.33 1.9-8.33 1.9z"
        />
        <path
          className="heroImgTwo116"
          d="M575.98 130.89l-17.46 7.13s12.39 1.77 20.13.19c5.24-1.06-2.43-7.54-2.67-7.32z"
        />
        <path
          className="heroImgTwo142"
          d="M536.34 141.76s-1.68 15.95-1.44 24.38c.18 6.36.2 18.55-1.31 28.93-.09.64-8.22.16-8.22.16s-2.92-39.54-3.78-46.04c-.86-6.52 1.65-11.22 14.75-7.43z"
        />
        <path
          className="heroImgTwo117"
          d="M587.43 145.6s-2.62 4.13-9.79 5.11c-2.6.35 4.18 46.28 4.18 46.28l10.61-3.4-2.05-50.79-2.95 2.8zM558.17 150.72s-13.87 1.98-23.12 2.95c-3.4.36-6.33-9.14-6.73-9.56-2.48-2.6-4.93-2.26-4.84-2.36 2.01-2.17 12.39-4.18 23.25-6.73 10.85-2.54 12.51-.94 12.51-.94l-1.07 16.64z"
        />
        <path
          className="heroImgTwo142"
          d="M546.72 135.02c-10.85 2.55-21.24 4.56-23.25 6.73-.08.09 2.36-.24 4.84 2.36.1.11.73 3.16 1.18 4.25-1.77-3.19 23.74-12.18 29.25-14.5-1.03-.34-4.03-.71-12.02 1.16z"
        />
        <path
          className="heroImgTwo117"
          d="M534.8 150.6L533.74 181.63 532.68 188.83 532.56 181.63 532.8 177.15 530.32 170.19 529.02 154.02 530.91 154.73 530.67 147.65z"
        />
        <path
          className="heroImgTwo142"
          d="M587.07 141.75l2.59.71.95 37.28-5.43 13.33 2.6-9.09-1.06-18.76s-3.78 9.08-3.78 8.62c0-.48 3.19-19.71 3.19-19.71s-3.54 2.95-3.19 2.83c.36-.11 2.13-13.44 4.13-15.21z"
        />
        <path
          className="heroImgTwo114"
          d="M542.6 98.16s-5.58-4.37-11.03 3.74c-7.99 11.88-11.46 24.52-12.74 28.17-.97 2.81-.43 10.73 2.83 14 .79.79 5.51.16 5.51.16l3.1-2.14-1.3-2.49-.07-1.97s3.96-9.56 8.83-18.59c4.87-9.04 4.87-20.88 4.87-20.88z"
        />
        <g>
          <path
            className="heroImgTwo116"
            d="M528.97 134.48l18.1.94s-17.43 8-18.01 6.62c-3.27-7.81-.09-7.56-.09-7.56z"
          />
          <path
            className="heroImgTwo116"
            d="M538.93 133.92c1.06-.51.96-.52.96-.52s.97 1.15 2.37 1.37c.7.1 2.94.14 2.94.14l3.33.08 1.54-.08s.87-.11 1.11.03c.67.43.1.78.1.78l-5.97.84 5.74.71s.72.21.76.6c.03.36-.21.35-.21.35l-6.67.71 2.92.7s.7.23.75.61c.06.41.09.53.09.53l-6.13-.6c-.01-.01-4.69-5.74-3.63-6.25z"
          />
          <path
            className="heroImgTwo116"
            d="M545.55 137.61l4.02.73s.91.06 1.32.36c.47.34.19.75.19.75l-6.15-.52.62-1.32z"
          />
        </g>
        <g>
          <path
            className="heroImgTwo166"
            d="M570.71 140.77l.02-.04-.02-1.14h-35.66v1.18l.12.21c.61.99 1.7 1.17 2.94 1.17h29.53c1.25 0 2.34-.17 2.94-1.17l.13-.21z"
          />
          <path
            className="heroImgTwo167"
            d="M541.08 139.86H564.69V140.66000000000003H541.08z"
          />
          <path
            className="heroImgTwo164"
            d="M570.69 139.72l.09-23.38c0-.61-.49-1.11-1.11-1.11H536.3c-.62 0-1.12.5-1.12 1.11l-.09 23.38h35.6z"
          />
          <path
            className="heroImgTwo166"
            d="M555.1 127.47a2.179 2.179 0 01-2.17 2.16c-1.19 0-2.16-.97-2.15-2.16.01-1.19.97-2.16 2.17-2.16 1.19 0 2.15.97 2.15 2.16z"
          />
        </g>
        <path
          className="heroImgTwo126"
          d="M526.62 143.52L525.38 135.58 527.44 133.46 532.01 134.19 531.08 142.97z"
        />
        <path
          className="heroImgTwo114"
          d="M582.11 137.83L582.59 134.91 576.81 129.71 573.68 131.13 577.77 138.96z"
        />
      </g>
      <g>
        <path
          className="heroImgTwo1"
          d="M533.66 651.59c-3.72 0-6.75-3.03-6.75-6.75 0-3.73 3.03-6.76 6.75-6.76s6.76 3.03 6.76 6.76c-.01 3.72-3.04 6.75-6.76 6.75zm0-10.13c-1.86 0-3.38 1.51-3.38 3.38 0 1.86 1.51 3.38 3.38 3.38 1.86 0 3.38-1.52 3.38-3.38a3.389 3.389 0 00-3.38-3.38zM556.17 639.21c-3.72 0-6.75-3.03-6.75-6.76 0-3.72 3.03-6.75 6.75-6.75 3.73 0 6.76 3.03 6.76 6.75 0 3.73-3.03 6.76-6.76 6.76zm0-10.13a3.38 3.38 0 00-3.38 3.37c0 1.86 1.51 3.38 3.38 3.38 1.86 0 3.38-1.52 3.38-3.38a3.38 3.38 0 00-3.38-3.37zM556.17 663.98c-3.72 0-6.75-3.03-6.75-6.76s3.03-6.75 6.75-6.75c3.73 0 6.76 3.03 6.76 6.75s-3.03 6.76-6.76 6.76zm0-10.13c-1.86 0-3.38 1.51-3.38 3.38 0 1.86 1.51 3.38 3.38 3.38 1.86 0 3.38-1.52 3.38-3.38 0-1.87-1.52-3.38-3.38-3.38z"
        />
        <g>
          <path
            className="heroImgTwo1"
            d="M539.06 643.73L537.26 640.87 550.77 632.43 552.57 635.29z"
          />
          <path
            transform="rotate(-58 544.9 651.585)"
            className="heroImgTwo1"
            d="M543.23 643.63H546.61V659.56H543.23z"
          />
        </g>
      </g>
      <path
        className="heroImgTwo131"
        d="M677.53 881.25h-66.28a7.36 7.36 0 01-7.36-7.36v-47.87c0-4.07 3.3-7.37 7.36-7.37h7.36v-14.73l16.57 14.73h42.35c4.07 0 7.36 3.3 7.36 7.37v47.87c.01 4.06-3.29 7.36-7.36 7.36z"
      />
      <g>
        <path
          className="heroImgTwo1"
          d="M661.69 847.91H669.0500000000001V870H661.69z"
        />
      </g>
      <g>
        <path
          className="heroImgTwo1"
          d="M646.96 858.96H654.32V870.01H646.96z"
        />
      </g>
      <g>
        <path className="heroImgTwo1" d="M632.23 829.5H639.59V870H632.23z" />
      </g>
      <g>
        <path className="heroImgTwo1" d="M617.51 840.54H624.87V870H617.51z" />
      </g>
      <g>
        <path
          className="heroImgTwo115"
          d="M575.44 0h-53.81c-3.3 0-5.98 2.68-5.98 5.98v41.85c0 3.3 2.68 5.98 5.98 5.98h17.95l2.99 2.99 5.98 5.98 5.98-5.98 2.99-2.99h17.93c3.3 0 5.98-2.68 5.98-5.98V5.98c-.01-3.3-2.69-5.98-5.99-5.98z"
        />
        <path
          className="heroImgTwo1"
          d="M569.47 29.89v-5.98h-6.28c-.39-1.91-1.15-3.68-2.19-5.25l4.45-4.45-4.23-4.23-4.45 4.45c-1.57-1.04-3.34-1.8-5.24-2.18V5.98h-5.98v6.29c-1.9.38-3.67 1.14-5.24 2.18L535.86 10l-4.23 4.23 4.45 4.45c-1.03 1.57-1.8 3.34-2.18 5.24h-6.28v5.98h6.28c.39 1.91 1.15 3.68 2.18 5.24l-4.45 4.45 4.23 4.22 4.45-4.45c1.57 1.04 3.34 1.8 5.24 2.19v6.28h5.98v-6.28c1.9-.39 3.67-1.15 5.24-2.19l4.45 4.45 4.23-4.22-4.45-4.45c1.04-1.56 1.8-3.34 2.19-5.25h6.28zm-20.93 5.98c-4.95 0-8.97-4.02-8.97-8.97 0-4.95 4.02-8.97 8.97-8.97 4.95 0 8.97 4.02 8.97 8.97 0 4.96-4.02 8.97-8.97 8.97z"
        />
      </g>
      <path
        className="heroImgTwo1"
        d="M385.76 265.13L376.42 270.53 367.07 275.93 367.07 265.13 367.07 254.34 376.42 259.73z"
      />
      <g>
        <path
          className="heroImgTwo1"
          d="M368.04 767.83h-16.65c.81-1.87 1.77-4.19 2-5.37.23-1.2 0-4.07 0-4.07a3.91 3.91 0 00-3.91-3.91h-1.96v6.04s-6.83 7.97-6.83 11.22v19.04a3.91 3.91 0 003.91 3.91h13.67c2.15 0 4.53-1.75 5.32-3.91l6.94-19.04c.78-2.17-.33-3.91-2.49-3.91z"
        />
        <path
          className="heroImgTwo1"
          d="M337.92 770.92H343.13V792.24H337.92z"
        />
        <path
          className="heroImgTwo1"
          d="M322.64 767.83H331.91999999999996V794.69H322.64z"
        />
      </g>
      <path
        className="heroImgTwo171"
        d="M740.48 254.8a5.885 5.885 0 00-8.31 0l-1.39 1.38-1.38-1.38c-2.29-2.29-6.01-2.29-8.31 0a5.873 5.873 0 000 8.3l1.39 1.39 8.3 8.3 8.31-8.3 1.39-1.39a5.873 5.873 0 000-8.3z"
      />
      <g>
        <path
          className="heroImgTwo122"
          d="M706.24 576.72c0 17.09-13.85 30.94-30.94 30.94-17.09 0-30.94-13.86-30.94-30.94 0-17.09 13.86-30.94 30.94-30.94 17.09-.01 30.94 13.84 30.94 30.94z"
        />
        <path
          className="heroImgTwo1"
          d="M689.37 591.7v-3.75c0-4.14-3.36-7.5-7.5-7.5h-13.13c-4.15 0-7.5 3.36-7.5 7.5v3.75h28.13zM681.87 568.26c0-3.62-2.94-6.56-6.56-6.56-3.63 0-6.56 2.94-6.56 6.56 0 3.63 2.94 6.56 6.56 6.56 3.62 0 6.56-2.93 6.56-6.56z"
        />
        <path
          className="heroImgTwo123"
          d="M709.37 555.93c0 6.64-5.39 12.03-12.03 12.03-6.65 0-12.03-5.39-12.03-12.03 0-6.65 5.39-12.03 12.03-12.03s12.03 5.38 12.03 12.03z"
        />
      </g>
      <g>
        <path
          className="heroImgTwo171"
          d="M824.87 190.3c0 17.83-14.45 32.29-32.29 32.29-17.83 0-32.28-14.46-32.28-32.29s14.45-32.28 32.28-32.28c17.83 0 32.29 14.45 32.29 32.28z"
        />
        <path
          className="heroImgTwo1"
          d="M804.83 181.57c-2.9-2.9-7.6-2.9-10.5 0l-1.75 1.74-1.75-1.74c-2.9-2.9-7.6-2.9-10.5 0-2.89 2.9-2.89 7.59 0 10.48l1.75 1.75 10.49 10.49 10.49-10.49 1.76-1.75c2.9-2.9 2.9-7.59.01-10.48z"
        />
      </g>
      <g>
        <path
          className="heroImgTwo155"
          d="M784.88 222.83c0 8.91-7.23 16.14-16.14 16.14-8.92 0-16.14-7.23-16.14-16.14s7.23-16.14 16.14-16.14 16.14 7.23 16.14 16.14z"
        />
        <path
          className="heroImgTwo1"
          d="M775.94 219.18h-5.69c.28-.64.6-1.43.68-1.83.08-.41 0-1.39 0-1.39 0-.74-.6-1.34-1.34-1.34h-.67v2.06s-2.33 2.72-2.33 3.83v6.5c0 .74.6 1.34 1.34 1.34h4.67c.73 0 1.55-.6 1.82-1.34l2.37-6.5c.26-.73-.12-1.33-.85-1.33z"
        />
        <path
          className="heroImgTwo1"
          d="M765.65 220.24H767.43V227.52H765.65z"
        />
        <path
          className="heroImgTwo1"
          d="M760.43 219.18H763.5999999999999V228.35H760.43z"
        />
      </g>
      <g>
        <path
          d="M863.81 219.64c0 8.28-6.71 14.99-14.99 14.99-8.28 0-14.99-6.71-14.99-14.99 0-8.28 6.71-14.99 14.99-14.99 8.27 0 14.99 6.71 14.99 14.99z"
          fill="#f9d05e"
        />
        <path
          d="M862.56 213.64c.2.98.3 1.99.3 3.02 0 8.48-6.87 15.34-15.34 15.34-5.1 0-9.61-2.48-12.4-6.3 2.33 5.25 7.59 8.92 13.71 8.92 8.28 0 14.99-6.71 14.99-14.99a15.15 15.15 0 00-1.26-5.99z"
          fill="#f4c249"
        />
        <path
          className="heroImgTwo174"
          d="M848.78 228.46c-2.25 0-4.47-.61-6.38-1.8l.57-.91c2.54 1.58 5.68 2.04 8.64 1.28 2.96-.76 5.49-2.7 6.94-5.31l.94.52c-1.59 2.86-4.37 4.99-7.61 5.83-1.03.26-2.07.39-3.1.39zM845.84 216.88c0 1.25-1.01 2.26-2.26 2.26-1.25 0-2.26-1.01-2.26-2.26 0-1.25 1.01-2.26 2.26-2.26 1.25 0 2.26 1.01 2.26 2.26z"
        />
        <g>
          <path
            className="heroImgTwo174"
            d="M857.48 218.77c-.06 0-.11-.01-.17-.03l-4.16-1.41a.516.516 0 01-.36-.51c0-.23.15-.43.37-.51l4.08-1.33a.53.53 0 11.33 1.01l-2.55.83 2.63.89c.28.09.43.4.34.68-.08.23-.29.38-.51.38z"
          />
        </g>
      </g>
      <g>
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1={356.2981}
          y1={575.4543}
          x2={356.2981}
          y2={592.8982}
        >
          <stop offset={0} stopColor="#f99b1c" />
          <stop offset={1} stopColor="#fec00f" />
        </linearGradient>
        <path
          fill="url(#SVGID_2_)"
          d="M373.29 591.98L356.3 580.34 339.31 591.98 339.31 556 356.3 556 373.29 556z"
        />
        <linearGradient
          id="SVGID_3_"
          gradientUnits="userSpaceOnUse"
          x1={356.2966}
          y1={514.0464}
          x2={356.2966}
          y2={551.137}
        >
          <stop offset={0} stopColor="#f99b1c" />
          <stop offset={1} stopColor="#fec00f" />
        </linearGradient>
        <path
          d="M381.94 542.37c0 14.16-11.48 25.65-25.64 25.65s-25.64-11.48-25.64-25.65c0-14.16 11.48-25.64 25.64-25.64s25.64 11.48 25.64 25.64z"
          fill="url(#SVGID_3_)"
        />
        <path
          className="heroImgTwo1"
          d="M356.3 527.07L361.18 533.03 368.89 533.13 367.27 540.67 371.99 546.75 365.09 550.19 363.28 557.68 356.3 554.42 349.31 557.68 347.5 550.19 340.6 546.75 345.33 540.67 343.71 533.13 351.42 533.03z"
        />
      </g>
      <g>
        <path
          d="M226.98 620.57l17.87-18.34-17.87-18.63h-41.15v36.97h41.15zm.6-22.25c2.03 0 3.67 1.68 3.67 3.77 0 2.08-1.64 3.77-3.67 3.77-2.03 0-3.67-1.69-3.67-3.77s1.64-3.77 3.67-3.77z"
          fill="#fdbf14"
        />
        <path
          className="heroImgTwo1"
          d="M206.53 592.42L209.48 598.57 216.09 599.55 211.31 604.33 212.44 611.08 206.53 607.89 200.62 611.08 201.75 604.33 196.97 599.55 203.58 598.56z"
        />
      </g>
      <path
        className="heroImgTwo1"
        d="M296.33 455.26l-1.57-4.71-6.39-1.82-.96 3.36 4.62 1.32L298 471.3l-1.04 3.73a5.833 5.833 0 00-4.82 5.74c0 3.22 2.62 5.84 5.84 5.84 2.61 0 4.8-1.73 5.55-4.1h9.22c.75 2.37 2.94 4.1 5.54 4.1 3.22 0 5.85-2.62 5.85-5.84 0-3.22-2.62-5.84-5.85-5.84-2.61 0-4.79 1.73-5.54 4.1h-9.22a5.844 5.844 0 00-3.07-3.53l.69-2.49h21.73c1.61 0 2.9-1.17 3.04-2.7l4.3-15.04h-33.89zm21.97 23.16c1.3 0 2.35 1.06 2.35 2.35 0 1.3-1.06 2.35-2.35 2.35s-2.35-1.05-2.35-2.35 1.05-2.35 2.35-2.35zm-20.32 4.7c-1.3 0-2.35-1.05-2.35-2.35 0-1.29 1.05-2.35 2.35-2.35s2.35 1.06 2.35 2.35c0 1.3-1.05 2.35-2.35 2.35zm24.54-13.61H301.1l-3.36-10.75h27.86l-3.08 10.75z"
      />
    </g>
  </svg>
)

export default HeroImageTwo
