import PropTypes from "prop-types"
import React from "react"

import WorkcationLogo from "../images/workcation-logo"
import TupleLogo from "../images/tuple-logo"
import MirageLogo from "../images/mirage-logo"
import StaticKitLogo from "../images/statickit-logo"
import TransistorLogo from "../images/transistor-logo"

const LogoCloud = ({ siteTitle }) => (
  <div className="bg-white">
    <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <TupleLogo className="h-12" />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <MirageLogo className="h-12" />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <StaticKitLogo className="h-12" />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
          <TransistorLogo className="h-12" />
        </div>
        <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
          <WorkcationLogo className="h-12" />
        </div>
      </div>
    </div>
  </div>
)

LogoCloud.propTypes = {
  siteTitle: PropTypes.string,
}

LogoCloud.defaultProps = {
  siteTitle: ``,
}

export default LogoCloud
