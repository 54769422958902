import React from "react";

const WorkcationLogo = props => (
  <svg fill="none" viewBox="0 0 180 48" {...props}>
    <path
      fill="#9FA6B2"
      fillRule="evenodd"
      d="M42.342 20.45l-7.596-4.385v20.371h8.88v1.974H.21v-1.974h3.947v-12.55l-3.678.92L0 22.89l20.81-5.203h3.08a9.42 9.42 0 00-.67 2.525l-.477 3.922 5.096-2.942v15.243h4.933v-20.37l-7.594 4.385a7.402 7.402 0 012.531-4.736h-4.064a7.39 7.39 0 016.557-2.933l-5.517-3.186a7.388 7.388 0 016.607.397 7.367 7.367 0 012.468 2.316 7.363 7.363 0 012.467-2.316 7.39 7.39 0 016.608-.397l-5.519 3.186a7.389 7.389 0 016.558 2.933H39.81a7.399 7.399 0 012.533 4.735zm-18.45 15.986v-9.867h-5.92v9.867h5.92zm-10.854-7.894a1.973 1.973 0 11-3.947 0 1.973 1.973 0 013.947 0z"
      clipRule="evenodd"
    />
    <path
      fill="#9FA6B2"
      d="M59.267 35.642h3.718L66.087 24.7l3.126 10.94h3.718l4.642-16.576h-3.434l-3.173 12.29-3.481-12.29H64.69l-3.457 12.29-3.174-12.29h-3.433l4.641 16.576z"
    />
    <path
      fill="#9FA6B2"
      fillRule="evenodd"
      d="M83.551 35.973c3.481 0 6.276-2.723 6.276-6.252 0-3.528-2.794-6.252-6.276-6.252-3.48 0-6.252 2.724-6.252 6.252 0 3.529 2.771 6.252 6.252 6.252zm0-2.984c-1.8 0-3.197-1.35-3.197-3.268 0-1.918 1.397-3.268 3.197-3.268 1.824 0 3.221 1.35 3.221 3.268 0 1.918-1.397 3.268-3.22 3.268z"
      clipRule="evenodd"
    />
    <path
      fill="#9FA6B2"
      d="M95.03 23.8v2.037c.617-1.61 2.108-2.273 3.6-2.273v3.41c-1.586-.19-3.6.521-3.6 3.008v5.66h-3.054V23.8h3.055zm16.304 11.842l-4.902-5.992 4.76-5.85h-3.647l-4.073 5.21v-9.946h-3.055v16.578h3.055v-5.376l4.31 5.376h3.552zm41.105-15.132c0 1.018-.853 1.87-1.895 1.87a1.89 1.89 0 01-1.871-1.87c0-1.019.853-1.895 1.871-1.895 1.042 0 1.895.876 1.895 1.894zm-.355 15.132h-3.055V23.8h3.055v11.84zm-5.209-8.904V23.8h-2.676v-3.315l-3.055.923V23.8h-2.06v2.937h2.06v4.925c0 3.197 1.444 4.452 5.731 3.979V32.87c-1.753.095-2.676.071-2.676-1.208v-4.925h2.676zm-23.028 6.157c-1.018 1.847-3.031 3.078-5.352 3.078-3.6 0-6.252-2.723-6.252-6.252 0-3.528 2.652-6.252 6.252-6.252 2.321 0 4.334 1.232 5.305 3.055l-2.629 1.54c-.474-.971-1.492-1.587-2.7-1.587-1.823 0-3.173 1.35-3.173 3.244 0 1.871 1.35 3.221 3.173 3.221 1.232 0 2.25-.592 2.724-1.563l2.652 1.516z"
    />
    <path
      fill="#9FA6B2"
      fillRule="evenodd"
      d="M134.277 25.198v-1.397h3.055v11.84h-3.055v-1.396c-.853 1.065-2.132 1.728-3.86 1.728-3.15 0-5.755-2.723-5.755-6.252 0-3.528 2.605-6.252 5.755-6.252 1.728 0 3.007.663 3.86 1.73zm-6.56 4.523c0 1.99 1.397 3.34 3.268 3.34 1.895 0 3.292-1.35 3.292-3.34 0-1.989-1.397-3.339-3.292-3.339-1.871 0-3.268 1.35-3.268 3.34zm32.799 6.252c3.481 0 6.275-2.723 6.275-6.252 0-3.528-2.794-6.252-6.275-6.252-3.481 0-6.252 2.724-6.252 6.252 0 3.529 2.771 6.252 6.252 6.252zm0-2.984c-1.8 0-3.197-1.35-3.197-3.268 0-1.918 1.397-3.268 3.197-3.268 1.823 0 3.22 1.35 3.22 3.268 0 1.919-1.397 3.268-3.22 3.268z"
      clipRule="evenodd"
    />
    <path
      fill="#9FA6B2"
      d="M171.995 25.127c.711-1.066 1.942-1.658 3.529-1.658 2.51 0 4.476 1.753 4.476 4.902v7.27h-3.055v-6.89c0-1.61-.971-2.44-2.345-2.44-1.492 0-2.605.876-2.605 2.937v6.394h-3.055V23.8h3.055v1.326z"
    />
  </svg>
);

export default WorkcationLogo;
